import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-otp-response',
  templateUrl: './otp-response.component.html',
  styleUrls: ['./otp-response.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OtpResponseComponent {
  constructor(
    private authService: AuthService,
    private matDialogRef: MatDialogRef<OtpResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ){
  }
// -----------Выход из приложения так как пароль был изменен
  logout(){
    this.matDialogRef.close()
    this.authService.logout();
    location.reload();
  }
  reload(){
    console.log(123)
    this.matDialogRef.close();
    location.reload();
  }
 }
