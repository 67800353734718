import { Component, OnInit, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";

interface WalletInfoData {
  isActive: string;
  currency: string;
  blockHeigth: number;
  walletConnections: number;
  lastUpdated: number;
  lastChecked: string;
  notice: any;
}

@Component({
  selector: "app-wallet-status",
  templateUrl: "./wallet-status.component.html",
  styleUrls: ["./wallet-status.component.scss"],
})
export class WalletStatus implements OnInit {
  constructor(public dataService: DataService) {}
  destroySubject$: Subject<void> = new Subject();
  public isLightTheme = false;
  public walletStatusData: WalletInfoData[] = [];
  public displayedColumns = [
    "isActive",
    "currency",
    "blockHeigth",
    "walletConnections",
    "lastUpdated",
    "lastChecked",
    "notice",
  ];
  public dataSource: MatTableDataSource<WalletInfoData>;
  public insideToggleButton: string = "Only show fiat-transations with updates";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

  ngOnInit(): void {
    this.fetchWalettsfromBit();
  }

  public fetchWalettsfromBit(): void {
    this.dataService
      .getAllCurrencieHealth()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((response) => {
        this.walletStatusData = response;
        this.dataSource = new MatTableDataSource(this.walletStatusData);
        this.ngAfterViewInit();
      });
  }
  public toggleWallets() {
    if (this.insideToggleButton === "Only show fiat-transations with updates") {
      this.insideToggleButton = "Show all fiat-transations";
      this.dataSource.data = this.dataSource.data.filter(item => {
        return item.notice
      })
    } else {
      this.insideToggleButton = "Only show fiat-transations with updates"
      this.dataSource.data = this.walletStatusData;
    }
  }
}
