import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-auth-qr',
  templateUrl: './auth-qr.component.html',
  styleUrls: ['./auth-qr.component.scss']
})
export class AuthQrComponent implements OnInit {
  @ViewChild('copy') el: ElementRef;

  constructor(
    private matDialogRef: MatDialogRef<AuthQrComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
  }
  onMouseDown(){
    this.el.nativeElement.style.transform ='scale(1.1)';
    if(navigator.clipboard){
      navigator.clipboard.writeText(this.data.key).then((r)=>r)
    }
  }
  onMouseUp(){
    this.el.nativeElement.style.transform ='scale(1)';
  }
  confirm(){
    this.matDialogRef.close(true);
  }
}
