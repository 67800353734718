import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {SvgService} from '../../services/svg.service';
import {CURRENCIES_WITH_TAG} from '../../app.constants';
import {CurrencyTagClass} from '../../models/currency-tag.class';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-wallet-buy-crypto-by-card',
  templateUrl: './wallet-deposit.component.html',
  styleUrls: ['./wallet-deposit.component.scss']
})
export class WalletDepositComponent {
  public key;
  public tag;
  public currency;
  public isCopied: boolean;
  public isPending = false;
  public isUserCompletedProfileForm = false;
  public currenciesWithTag = CURRENCIES_WITH_TAG;
  public currencyWithTag: CurrencyTagClass;
  public isCurrencyWithTag: boolean;
  public isTechnicalError = false;
  public isLightTheme = false;
  public blockedMarkets = [];
  destroySubject$: Subject<void> = new Subject();

  constructor(private dialogRef: MatDialogRef<WalletDepositComponent>,
              public dataService: DataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public svgService: SvgService,
  ) {
    this.loadIsUserCompletedProfileFormData();
    console.log(data);
    if (data.blockedMarkets) {
      this.blockedMarkets = data.blockedMarkets;
    }
    const userId = +localStorage.getItem('visible-set');
    if (data.currency && data.currency === 'USDT') {
      // console.log('userId', userId);
      if ((userId === 344) || (userId === 135) || (userId === 309) || (userId === 229) || (userId === 343) || (userId === 50)) {

        // diazz.turdiyev@gmail.com (229 ID)
        // n.kalenov@biteeu.com (344 ID)
        // veidam@mail.ru (343 ID)

        // this.isTechnicalError = false;
      } else {
        // this.isTechnicalError = true;
      }
      // console.log('this.isTechnicalError', this.isTechnicalError);
    }
    this.isCurrencyWithTag = this.currenciesWithTag.some((currency) => {
      return currency.currency === data.currency;
    });
    if (this.isCurrencyWithTag) {
      this.currencyWithTag = Object.assign({}, this.currenciesWithTag.filter((currency) => {
        return currency.currency === data.currency;
      })[0]);
    }
    this.key = data.key;
    this.tag = this.isCurrencyWithTag ? (data.tag || '') : undefined;
    this.currency = data.currency;

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(isLightTheme => {
        this.isLightTheme = isLightTheme;
      });
  }
  public close() {
    this.dialogRef.close();
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }
  public copyToClipboard() {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.key;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopied = true;
    setTimeout(() => this.isCopied = false, 1000);
  }
  public loadIsUserCompletedProfileFormData() {
    this.isUserCompletedProfileForm = true;
/*
    this.isUserCompletedProfileForm = this.dataService.getIsUserCompletedProfileForm();
    // console.log('isUserCompletedProfileForm 1', this.isUserCompletedProfileForm);
    if (!this.isUserCompletedProfileForm) {
      this.dataService.passIsVisibleProfileEmptyFields(true);
      this.dataService.setIsVisibleProfileEmptyFields(true);
    }
    this.dataService.getIsUserCompletedProfileFormEmitter()
      .subscribe(data => {
        this.isUserCompletedProfileForm = data;
        // console.log('isUserCompletedProfileForm 3', this.isUserCompletedProfileForm);
        if (!this.isUserCompletedProfileForm) {
          this.dataService.passIsVisibleProfileEmptyFields(true);
          this.dataService.setIsVisibleProfileEmptyFields(true);
        }
      });
*/
  }

  public isCurrencyBlocked(currency: string): boolean {
    return this.blockedMarkets.includes(currency);
  }

}
