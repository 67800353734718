import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-smart-input',
  templateUrl: './new-smart-input.component.html',
  styleUrls: ['./new-smart-input.component.scss'],
})
export class NewSmartInputComponent implements OnInit {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;
  @Input() isPasswordProps: boolean = false;
  @Input() withTooltipProps: boolean = false;
  @Input() withHideProps: boolean = false;
  @Input() inputTypeProps: string = 'text';

  public isPasswortType: boolean = false;
  public isHideInput: boolean = false;
  public isActiveTooltip: boolean = false;
  public passwordRequirements = [
    {
      key: 'length',
      message: 'REGISTER.PASSWORD_LENGTH_RANGE',
      isValid: false,
    },
    { key: 'digit', message: 'REGISTER.PASSWORD_MIN_DIGIT', isValid: false },
    {
      key: 'uppercase',
      message: 'REGISTER.PASSWORD_MIN_UPPERCASE',
      isValid: false,
    },
    {
      key: 'specialChar',
      message: 'REGISTER.PASSWORD_SPECIAL_CHAR_REQUIRED',
      isValid: false,
    },
  ];

  constructor() {}

  public ngOnInit(): void {
    this.isPasswortType = this.isPasswordProps;
    this.inputTypeProps = this.isPasswordProps ? 'password' : 'text';
    this.isHideInput = this.withHideProps;
    if (this.isPasswordProps && this.withTooltipProps) {
      this.updateRequirements();

      this.formControlProps.valueChanges.subscribe((value: string) => {
        this.updateRequirements();
      });
    }
  }

  public onChangeType(): void {
    this.isPasswortType = !this.isPasswortType;
    this.inputTypeProps = this.isPasswortType ? 'password' : 'text';
  }

  public onToggleHide(): void {
    this.isHideInput = !this.isHideInput;
  }

  public onFocus(): void {
    this.isActiveTooltip = true;
  }

  public onBlur(): void {
    this.isActiveTooltip = false;
  }

  private updateRequirements(): void {
    const errors = this.formControlProps.errors || {};
    this.passwordRequirements = this.passwordRequirements.map((req) => ({
      ...req,
      isValid: !errors[req.key],
    }));
  }
}
