import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-toggle2-fa-response',
  templateUrl: './toggle2-fa-response.component.html',
  styleUrls: ['./toggle2-fa-response.component.scss']
})
export class Toggle2FAResponseComponent implements OnInit {
  constructor(
    private matDialogRef: MatDialogRef<Toggle2FAResponseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
  }
  close(){
    this.matDialogRef.close(this.data.isConnected);
  }
}
