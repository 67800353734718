import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-input',
  templateUrl: './new-input.component.html',
  styleUrls: ['./new-input.component.scss'],
})
export class NewInputComponent implements OnInit {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;
  @Input() isPasswordProps: boolean = false;
  @Input() isInstantBuyTab: boolean;
  @Input() withMaxProps: number | null = null;
  @Input() scanBarcodeProps: boolean = false;
  @Input() inputTypeProps: string = 'text';

  public logoPassword: string = 'eye';

  constructor() {}

  ngOnInit(): void {
    if (this.isPasswordProps) {
      this.inputTypeProps = 'password';
    }
  }

  public selectMax(): void {
    this.formControlProps.setValue(this.withMaxProps);
  }

  public togglePassword(): void {
    this.logoPassword = this.logoPassword === 'eye' ? 'eye-off' : 'eye';
    this.inputTypeProps =
      this.inputTypeProps === 'password' ? 'text' : 'password';
  }

  public onBlur(): void {
    const value = this.formControlProps.value;

    if (this.inputTypeProps === 'date' && !this.isValidDate(value)) {
      this.formControlProps.setErrors({ invalidDate: true });
      return;
    }

    if (value) {
      this.formControlProps.setValue(this.formControlProps.value.trim());
    }
  }

  private isValidDate(value: any): boolean {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;

    if (!dateRegex.test(value)) {
      return false;
    }

    const [year, month, day] = value.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    return (
      date.getFullYear() === year &&
      date.getMonth() === month - 1 &&
      date.getDate() === day
    );
  }
}
