import {Component, OnDestroy, OnInit} from '@angular/core';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {RegistrationStatusesClass} from '../../models/registration-statuses.class';
import {DepositWithdrawalInterface} from '../../models/deposit-withdrawal.model';
import {MarketLastPriceInterface} from '../../models/market-last-price.model';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {MatDialog} from '@angular/material/dialog';
import {NotVerifiedComponent} from '../../dialogs/not-verified/not-verified.component';
import {Router} from '@angular/router';
import {ProfileState} from '../../store/profile.state';
import {fromMobx} from '../../store/rx-from-mobx';
import {IMarketList} from '../../interfaces/market-list.interface';
import {MarketPair} from '../../interfaces/market-pair';
import {mark} from '@angular/compiler-cli/src/ngtsc/perf/src/clock';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
  public marketsListAll = [];
  public marketsListFav = [];
  public marketsListBTC = [];
  public marketsListETH = [];
  public marketsListUSDT = [];
  public marketsListEUR = [];
  public marketListSocket = [];
  public popularCoinList = [];
  private isFiltering: boolean;
  // public totalDepositUSDT = 0;
  public totalDepositsLimit1USDT = 50;
  public kycStatus: number;
  public notVerifiedOpen = false;

  public marketListPairs = [];
  public newCoinList;

  public userId: number;
  public isTestAccount = false;
  destroySubject$: Subject<void> = new Subject();

    constructor(public dataService: DataService,
              public authService: AuthService,
              public profileState: ProfileState,
              private router: Router,
              public dialog: MatDialog) {}

  ngOnInit(): void {
    const bodyTag = document.getElementsByTagName('body')[0];
    // bodyTag.style.backgroundColor = '#22426A';
    bodyTag.style.backgroundColor = '#F5F7FA';

    this.isTestAccount = this.profileState.isTestAccount;
    this.getMarketsList();
    if (this.authService.isLoggedIn) {
      this.profileState.loadUserProfileIfEmpty();
      fromMobx(() => this.profileState.storagedUserId)
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(
          (userId) => {
            this.userId = userId;
            this.dataService.joinRoomUserId('' + userId);
            this.checkFreeCoinsStatus(userId);
          }
        );
      this.dataService.SubscribeToUpdateFavs()
        .subscribe((data: any) => {
          if (data.u) {
            this.getMarketsList();
          }
        });

      this.getKycStatus();
    } else {
      this.profileState.checkUserIsCookiesAgreed();
    }
  }

  ngOnDestroy() {
    this.dataService.leaveRoomUserId('' + this.userId);
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public getKycStatus() {
    this.dataService.getUserKycStatus()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((res: any) => {
        this.kycStatus = res.status;
      }, () => {
        // console.log(error1);
      });
  }

  public openNotVerified() {
    this.dialog.open(NotVerifiedComponent).afterClosed()
      .subscribe(() => {
        this.router.navigateByUrl('user-settings/identity-verification').then();
      });
    this.notVerifiedOpen = true;
  }

  public checkFreeCoinsStatus(userId: number) {
    this.dataService.getFreeCoinsStatus(userId)
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((result: RegistrationStatusesClass) => {

          if (result.registrationBonus === 0) {
            // this.dataService.getBalances()
            //   .pipe(takeUntil(this.destroySubject$))
            //   .subscribe((fiat-transations: Array<WalletBalanceInterface>) => {
            //     const currencyForBonuses = 'SPC';
            //     // const currencyForBonuses = 'ADA';
            //
            //     const walletForBonuses = fiat-transations.filter(wallet => {
            //       return (wallet.currency === currencyForBonuses);
            //     });
            //     if (walletForBonuses && walletForBonuses.length
            //       && walletForBonuses[0].id && (walletForBonuses[0].id.length > 1)) {
            //       try {
            //         this.addRegistrationBonus(userId);
            //       }  catch (e) {
            //       }
            //     } else {
            //       this.dataService.passMessageTypeForAlertModal(2);
            //       this.dataService.setMessageTypeForAlertModal(2);
            //     }
            //   });
          }

          if (result.depositeBonus === 0) {
            let totalDepositUSDT: number;
            // totalDepositUSDT = this.dataService.getTotalDepositUSDT();
            // if (totalDepositUSDT && ((totalDepositUSDT) > this.totalDepositsLimit1USDT)) {
            //   this.addDepositBonus(userId);
            // }
            fromMobx(() => this.profileState.totalDepositUSDT)
              .pipe(takeUntil(this.destroySubject$))
              .subscribe(data => {
                totalDepositUSDT = data;
                if (totalDepositUSDT && ((totalDepositUSDT) > this.totalDepositsLimit1USDT)) {
                  try {
                    this.addDepositBonus(userId);
                  } catch (e) {}
                }
              });
          }

          if (result.KycVerificationBonus === 0) {
            this.dataService.getUserKycStatus()
              .pipe(takeUntil(this.destroySubject$))
              .subscribe((res: any) => {
                if (res.status && (res.status === 1)) {
                  try {
                    this.addKycBonus(userId);
                  } catch (e) {}
                }
              });
          }

          this.profileState.getTransactions();
      }, error => {
        return observableThrowError(error);
      });
  }

  // public addRegistrationBonus(userId: number) {
  //       this.dataService.postRegistrationBonus(userId)
  //         .pipe(takeUntil(this.destroySubject$))
  //         .subscribe(() => {
  //           this.dataService.passMessageTypeForAlertModal(1);
  //           this.dataService.setMessageTypeForAlertModal(1);
  //         });
  // }

  public addDepositBonus(userId: number) {
        this.dataService.postDepositBonus(userId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.dataService.passMessageTypeForAlertModal(3);
            this.dataService.setMessageTypeForAlertModal(3);
          });
  }

  public addKycBonus(userId: number) {
        this.dataService.postKycBonus(userId)
          .pipe(takeUntil(this.destroySubject$))
          .subscribe(() => {
            this.dataService.passMessageTypeForAlertModal(4);
            this.dataService.setMessageTypeForAlertModal(4);
          });
  }

  public getMarketsList() {
    this.dataService.EmitSubscribeToSummaryDeltas();

    this.dataService.marketsDetails$
      .pipe(
        takeUntil(this.destroySubject$)
      )
      .subscribe(marketsList => {
        // const filteredMarketList = marketsList.filter((marketListItem: any) => {
        //   return !((marketListItem.MarketName.includes('BTC') && !marketListItem.MarketName.includes('USDT'))
        //     || (marketListItem.MarketName.includes('ETH') && !marketListItem.MarketName.includes('USDT')));
        // });
        this.marketListPreparation(marketsList);
        // console.log('MARKETS DATA', marketsList);
      });
  }

  public marketListPreparation(data): void {
    this.marketsListAll = data.sort((a: any, b: any) => +b.BaseVolumeUSDT - +a.BaseVolumeUSDT);
    this.authService.isLoggedIn$.pipe(
      takeUntil(this.destroySubject$)
    ).subscribe(
      (isLoggedIn) => {
        if (isLoggedIn) {
          this.filterFavorites();
        } else {
          this.marketsListFav = [];
        }
      }
    );
    if (this.authService.isLoggedIn) {
      this.filterFavorites();
    }
    this.marketsListBTC = this.filterMarket('BTC').sort((a: any, b: any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListETH = this.filterMarket('ETH').sort((a: any, b: any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListUSDT = this.filterMarket('USDT').sort((a: any, b: any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.marketsListEUR = this.filterMarket('EUR').sort((a: any, b: any) => +b.BaseVolume24 - +a.BaseVolume24);
    this.getPopularCoinList();
  }
  private filterFavorites(): any {
    if (!this.isFiltering) {
      this.isFiltering = true;
      this.dataService.getFavoriteMarkets()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe(res => {
          this.marketListPairs = res;
          this.marketsListFav = this.marketsListAll.filter(v => res.includes(v.MarketName));
          this.isFiltering = false;
      });
    }
  }

  private filterMarket(curr): any {
    return this.marketsListAll.filter(v => v.MarketName.split('-')[0] === curr);
  }

  private getPopularCoinList() {
    this.popularCoinList = this.marketsListAll
      .sort((a: any, b: any) => +b.BaseVolume24 - +a.BaseVolume24)
      .slice(0, 3);
    this.newCoinList = this.marketsListAll
      .filter(coin => coin.MarketName.includes('AAVE') || coin.MarketName.includes('BNB') || coin.MarketName.includes('SOL'));
  }
}
