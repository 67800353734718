import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {DataService} from '../../../services/data.service';
import {HomeSearchPipe} from '../../../pipes/home/home-search.pipe';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil, map} from 'rxjs/operators';
import {AuthService} from '../../../services/auth.service';
import {MarketPair} from '../../../interfaces/market-pair';

enum MarketEnum {
  BTC = 'BTC',
  ETC = 'ETH',
  USDT = 'USDT',
  All = 'All'
}

@Component({
  selector: 'app-market-list',
  templateUrl: './market-list.component.html',
  styleUrls: ['./market-list.component.scss'],
  providers: [HomeSearchPipe]
})
export class MarketListComponent implements OnChanges, OnDestroy, OnInit {
  @Input() marketName;
  @Input() marketListPairs;
  @Input() marketBtc;
  @Input() marketEtc;
  @Input() marketUSDT;
  @Input() marketAll;
  @Input() popularCoinList;
  @Input() hotCoinListProps: any;
  @Input() newCoinsList: any;

  // new
  @Input() marketNames;
  @Input() marketListFav;
  // public _marketsData: any;
  public isLightTheme = false;
  public pagination = 0;
  public marketsList;
  public searchText;
  public fearIndex;
  private maxRetries = 3;
  private retryDelay = 1000;

  currentMarket = 'All';

  public sortParams = {
    sortByVolume: '',
    sortByPair: '',
    sortByCurrency: '',
    sortByChange24h: '',
  };
  destroySubject$: Subject<void> = new Subject();

  marketSymbols: Record<MarketEnum, string> = {
    [MarketEnum.BTC]: '₿',
    [MarketEnum.ETC]: 'Ξ',
    [MarketEnum.USDT]: '₮',
    [MarketEnum.All]: '',
  };

  get marketSymbol() {
    return this.marketSymbols[this.currentMarket];
  }

  constructor(public dataService: DataService,
              public authService: AuthService,
              public router: Router,
  ) {
    // if (window.localStorage.getItem('isLightTheme') === 'true') {
    //   this.isLightTheme = true;
    // } else {
    //   this.isLightTheme = this.dataService.getIsLightTheme();
    // }
    // this.dataService.getIsLightThemeEmitter()
    //   .pipe(takeUntil(this.destroySubject$))
    //   .subscribe(data => {
    //     this.isLightTheme = data;
    //   });
    this.dataService.isMarketListPageSubject.next(true);
  }

  // getMarketSymbol(market) {
  //   return this.marketSymbols[market.MarketName.split('-')[0]] || '';
  // }

  ngOnInit() {
    this.getFearGreedIndex();
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.isMarketListPageSubject.next(false);
  }

  getMarkets() {
    const name = this.currentMarket;
    if (name === MarketEnum.BTC) {
      return this.marketBtc;
    } else if (name === MarketEnum.ETC) {
      return this.marketEtc;
    } else if (name  === MarketEnum.USDT) {
      return this.marketUSDT;
    } else if (name  === MarketEnum.All) {
      return this.marketAll;
    } else {
      return this.marketListFav;
    }
  }

  setMarketsData(name: any): void {
    this.currentMarket = name;
    this.searchInMarketsList(false);
  }

  ngOnChanges(): void {
    // this._marketsData = JSON.parse(JSON.stringify(this.marketsData));
    this.searchInMarketsList(false);
  }
  private paginationHell(data) {
    const size = 400;
    const subarray = [];
    const paginationArray = [];
    for (let i = 0; i < Math.ceil(data.length / size); i++) {
      subarray[i] = data.slice((i * size), (i * size) + size);
      paginationArray.push(i);
    }
    subarray.push(paginationArray);

    return subarray;
  }

  public searchInMarketsList(isChangedInputSearch: boolean): void {
    if (isChangedInputSearch) {
      this.pagination = 0;
    }

    this.marketsList = (this.searchText)
                      ? this.paginationHell(this.homeSearchPipeCalling(this.getMarkets(), this.searchText))
                      : this.paginationHell(this.getMarkets());

  }
  public homeSearchPipeCalling(dataArray: Array<any>, searchText: string): Array<any> {
    return new HomeSearchPipe().transform(this.getMarkets(), this.searchText);
  }

  public checkForBitcoinName(data: string, pair?: string): string {
    let result = '';
    switch (data.toLowerCase()) {
      case 'bitcoin':
        result = (pair.toUpperCase() === 'USDT-BTC' || pair.toUpperCase() === 'EUR-BTC') ? 'Bitcoin' : 'Bitcoin Cash';
        break;
      default: result = data;
    }
    switch (pair.toUpperCase().split('-')[1]) {
      case 'XRP':
        result = 'XRP';
        break;
      case 'BSV':
        result = ' Bitcoin SV';
        break;
      case 'BAT':
        result = ' Basic Attention Token';
        break;
      case 'DOT':
        result = 'Polkadot';
        break;
      // default: result = data;
    }
    return result;
  }
  public navigateToTrades(pair): void {
    this.dataService.storePair(pair);
    this.router.navigate([`trades/${pair}`]).then();
  }
  public iteration(plus): void {
    plus ? this.pagination++ : this.pagination--;
  }
  public changeSort(): void {
    // if (this.sortParam === 'off')
    //   this.sortParam = 'up';
    // else if (this.sortParam === 'up')
    //   this.sortParam = 'down';
    // else this.sortParam = 'off';
  }
  public toggleSort(param) {
    // console.log('current ' + param, this.sortParams[param]);
    // this.toggleDefaultSort();
    // switch (this.sortParams[param]) {
    //   case 'up':
    //     this.sortParams[param] = 'down';
    //     break;
    //   case 'down':
    //     this.sortParams[param] = 'up';
    //     break;
    //   default: this.sortParams[param] = 'down';
    // }
    // console.log(this.sortParams);
    // console.log('-----');

    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = 'up';
    } else {
      this.sortParams[param] = 'up';
    }
  }
  public toggleDefaultSort() {
  this.sortParams = {
      sortByVolume: '',
      sortByPair: '',
      sortByCurrency: '',
      sortByChange24h: ''
    };

  }

  formattedMarketName(marketName: string): string {
    return marketName?.split('-')?.reverse()[0];
  }

  formatMarketName(marketName: string) {
    const updateMarketName = marketName?.split('-')?.reverse()[0];
    switch (updateMarketName) {
      case 'BTC':
        return 'Bitcoin';
      case 'BCH':
        return 'Bitcoin Cash';
      case 'ADA':
        return 'Cardano';
      case 'XRP':
        return 'Ripple';
      case 'ETH':
        return 'Ethereum';
      case 'USDT':
        return 'Tether';
      case 'AAVE':
        return 'Aave';
      case 'ARB':
        return 'Arbitrum';
      case 'WAVES':
        return 'Waves';
      case 'ATOM':
        return 'Cosmos';
      case 'BAL':
        return 'Balancer';
      case 'FTM':
        return 'Fantom';
      case 'VET':
        return 'VeChain';
      case 'HBAR':
        return 'Hedera Hashgraph';
      case 'IMX':
        return 'Immutable X';
      case 'MKR':
        return 'Maker';
      case 'OP':
        return 'Optimism';
      case 'TRX':
        return 'Tron';
      case 'THETA':
        return 'Theta network';
      case 'GRT':
        return 'The Graph';
      case 'INJ':
        return 'Injective Protocol';
      case 'RUNE':
        return 'Thor Chain';
      case 'USDP':
        return 'Pax Dollars';
      case 'AVAX':
        return 'Avalanche';
      case 'NEAR':
        return 'Near Protocol';
      case 'UNI':
        return 'Unidswap';
      case 'BNB':
        return 'Bnb';
      case 'TON':
        return 'Toncoin';
    }
  }

  formattedMarketTradeName(marketName: string): string {
    return marketName?.split('-')?.reverse()?.join('-');
  }

  generateImgSrc(name: string): string {
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
    const coin = name?.split('-')[1];

    switch (coin) {
      case 'BTC':
        return 'bitcoin_icon';
      case 'BCH':
        return 'bitcoin_cash_icon';
      case 'ADA':
        return 'cardano_icon';
      case 'XRP':
        return 'ripple_icon';
      case 'ETH':
        return 'ethereum_icon';
      case 'USDT':
        return 'tether_icon';
      case 'AAVE':
        return 'aave_icon';
      case 'ARB':
        return 'arb_icon';
      case 'WAVES':
        return 'waves_icon';
      case 'ATOM':
        return 'cosmos_icon';
      case 'BAL':
        return 'balancer_icon';
      case 'FTM':
        return 'fantom_icon';
      case 'VET':
        return 've_chain_icon';
      case 'HBAR':
        return 'hedera_hashgraph_icon';
      case 'IMX':
        return 'immutable_x_icon';
      case 'MKR':
        return 'maker_icon';
      case 'OP':
        return 'optimism_icon';
      case 'TRX':
        return 'tron_icon';
      case 'THETA':
        return 'theta_network_icon';
      case 'GRT':
        return 'the_graph_icon';
      case 'INJ':
        return 'injective_protocol_icon';
      case 'RUNE':
        return 'thor_chain_icon';
      case 'USDP':
        return 'pax_dollar_icon';
      case 'AVAX':
        return 'avalance_icon';
      case 'NEAR':
        return 'near_protocol_icon';
      case 'UNI':
        return 'uniswap_icon';
      case 'BNB':
        return 'bnb_icon';
      case 'SOL':
        return 'solana_icon';
      case 'TON':
        return 'ton_icon';
    }
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
  }

  formatNumber(value: number, toFixedValue: number): string {
    let suffix = '';
    if (value >= 1_000_000_000) {
      value = value / 1_000_000_000;
      suffix = 'B'; // Миллиарды
    } else if (value >= 1_000_000) {
      value = value / 1_000_000;
      suffix = 'M'; // Миллионы
    } else if (value >= 1_000) {
      value = value / 1_000;
      suffix = 'K'; // Тысячи
    }

    const [integerPart, decimalPart] = value.toFixed(toFixedValue).split('.');

    const integerPartToLocaleString = parseInt(integerPart, 10).toLocaleString('en-US');

    return `${integerPartToLocaleString}.${decimalPart}${suffix}`;
  }

  public toggleFav(market: string, isFav: boolean, event: PointerEvent): void {
    event.stopPropagation();
    const data = {market_name: market };
    if (isFav) {
      this.dataService.removeFromFavoriteMarkets(data)
        .subscribe();
    } else {
      this.dataService.addToFavoriteMarkets(data).subscribe();
    }
  }

  getSrcFav(market): string {
    return `assets/img/${this.marketListPairs.includes(market) ? 'favorite-star-active' : 'favorite-star-no-active'}.svg`;
  }



  getFearGreedIndex() {
    this.fetchFearGreedIndex(1);
  }

  private fetchFearGreedIndex(attempt: number) {
    this.dataService.getFearGreedIndex().subscribe({
      next: (res) => this.handleSuccess(res),
      error: (err) => this.handleError(err, attempt),
    });
  }

  private handleSuccess(res: any) {
    if (res.data && res.data.length > 0) {
      this.fearIndex = Number(res.data[0].value);
    } else {
      console.error('No data available in Fear and Greed Index response');
    }
  }

  private handleError(err: any, attempt: number) {
    if (attempt < this.maxRetries) {
      setTimeout(() => this.fetchFearGreedIndex(attempt + 1), this.retryDelay);
    } else {
      console.error('Maximum retry attempts reached');
    }
  }
}
