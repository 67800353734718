import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-email-change',
  templateUrl: './email-change.component.html',
  styleUrls: ['./email-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailChangeComponent {
  constructor(
    private dialogRef: MatDialogRef<EmailChangeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){
  }
// -----------> Закрываем модалку без подтверждения
  onClose(){
    this.dialogRef.close();
  }
// -----------> Закрываем модалку с подстверждением
  confirm(){
    this.dialogRef.close(true);
  }
}
