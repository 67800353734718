import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-auth-hint',
  templateUrl: './auth-hint.component.html',
  styleUrls: ['./auth-hint.component.scss']
})
export class AuthHintComponent {

  constructor(
    private matDialogRef: MatDialogRef<AuthHintComponent>,
    @Inject(MAT_DIALOG_DATA) public isEnable2fa: boolean,
  ) {
  }
  confirm(is2FAEnable:boolean){
    this.matDialogRef.close(is2FAEnable);
  }
}
