import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent {
  @Input('tableName') tableNameProps: string;
  @Input('tableRowsData') tableRowsDataProps: any[] = [];
  @Input('tableRows') tableRowsProps: any[] = [];
  @Input('noDataText') noDataTextProps: string = 'Нет данных';

  private unsubscribe$$: Subject<void> = new Subject<void>();

  constructor(private _translateService: TranslateService) {}

  ngOnDestroy(): void {
    this.unsubscribe$$.next();
    this.unsubscribe$$.complete();
  }

  public getStatus(status: string): string {
    switch (status) {
      case 'NEW':
        return this._translateService.instant('ADMIN.WITHDRAW.STATUS_NEW');
      case 'RESOLVED':
        return this._translateService.instant('ADMIN.WITHDRAW.STATUS_RESOLVED');
      default:
        return status;
    }
  }

  public getStatusColor(status: string): string {
    switch (status) {
      case 'NEW':
        return '#0090FF';
      case 'RESOLVED':
        return '#00C389';
      default:
        return '';
    }
  }
}
