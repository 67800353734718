import { environment as defaultEnvironment } from 'src/environments/environment.defaults';

export const environment = {
  ...defaultEnvironment,
  // host: 'http://65.2.6.202:3333/',
  host: 'https://api.intebix.kz/',
  homepage: 'https://intebix.kz/home',
  tradePage: 'https://trade.intebix.kz/markets',
  ssoPage: 'https://sso.intebix.kz/',
  name: 'dev',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
