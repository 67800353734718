import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
})
export class ConfirmEmailComponent implements OnInit, OnChanges {
  @Input() public emailProps: string = '';
  @Input() public errorConfirmProps: boolean = false;

  @Output() public nextEvent: EventEmitter<string> = new EventEmitter();
  @Output() public resendEvent: EventEmitter<void> = new EventEmitter();

  array = Array(6).fill(null);
  values: string[] = new Array(6).fill('');
  timer: number = 59;
  isTimerRunning: boolean = false;

  public ngOnInit(): void {
    this.startTimer();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.errorConfirmProps && changes.errorConfirmProps.currentValue) {
      this.reset();
    }
  }

  public maskEmail(): string {
    const email = this.emailProps;

    if (!email || typeof email !== 'string') {
      return '';
    }

    const [localPart, domain] = email.split('@');
    if (!domain || localPart.length <= 3) {
      return email;
    }

    const visiblePart = localPart.slice(0, 3);
    const maskedPart = '*'.repeat(localPart.length - 3);

    return `${visiblePart}${maskedPart}@${domain}`;
  }

  public onInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;

    if (!/^\d$/.test(value)) {
      input.value = '';
      return;
    }

    this.values[index] = value;

    if (index < this.array.length - 1 && value) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      nextInput?.focus();
    }

    if (this.isComplete()) {
      this.submit();
    }
  }

  public onPaste(event: ClipboardEvent): void {
    event.preventDefault();
    const pastedData = event.clipboardData?.getData('text').trim().slice(0, 6) || '';

    if (pastedData.length !== this.array.length || !/^\d+$/.test(pastedData)) {
      return;
    }

    pastedData.split('').forEach((value, index) => {
      this.values[index] = value;

      const inputElements = document.querySelectorAll('.inputs-container input');
      if (inputElements[index + 1]) {
        (inputElements[index + 1] as HTMLInputElement).focus();
      }
    });

    if (this.isComplete()) {
      this.submit();
    }
  }

  private reset(): void {
    this.array.fill(null);
    this.values.fill('');
  }

  public onBackspace(event: KeyboardEvent, index: number): void {
    if (!this.values[index] && index > 0) {
      const prevInput = (event.target as HTMLInputElement)
        .previousElementSibling as HTMLInputElement;
      prevInput?.focus();
    }
  }

  private isComplete(): boolean {
    return this.values.every((value) => value.length === 1);
  }

  private submit(): void {
    const value = this.values.join('');
    this.nextEvent.emit(value);
  }

  private startTimer(): void {
    this.isTimerRunning = true;
    this.timer = 59;

    const interval = setInterval(() => {
      this.timer--;
      if (this.timer <= 0) {
        clearInterval(interval);
        this.isTimerRunning = false;
      }
    }, 1000);
  }

  public resend(): void {
    if (this.timer < 1) {
      this.resendEvent.emit();
      this.reset();
      this.startTimer();
    }
  }
}
