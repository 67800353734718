import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyLetters]',
})
export class OnlyLettersDirective {
  @HostListener('input', ['$event'])
  onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/[^a-zA-Zа-яА-ЯёЁ ]/g, '');
    input.dispatchEvent(new Event('input'));
  }
}
