import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-new-datepicker',
  templateUrl: './new-datepicker.component.html',
  styleUrls: ['./new-datepicker.component.scss'],
})
export class NewDatepickerComponent {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;

  public currentDateTime: string;

  constructor() {}

  setCurrentDateTime(): void {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');

    // Форматируем дату в строку "yyyy-MM-ddTHH:mm"
    this.currentDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
  }
}
