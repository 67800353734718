import {AfterViewInit, Component, OnInit} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {map, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {WalletDepositComponent} from '../../../dialogs/wallet-deposit/wallet-deposit.component';
import {MatDialog} from '@angular/material/dialog';
import {WalletBalanceInterface} from '../../../models/wallet-balance.model';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-buy-crypto-by-card',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss', '../common-styles/form-with-table.scss']
})
export class DepositComponent implements AfterViewInit, OnInit {
  minDepositSum = undefined;
  public isWalletWorking: boolean;

  currencyOptions$ = this.dataService.currencyOptions$.pipe(
    map((data) => data.map((v) => (
      {...v, value: v.value.currency}
    )))
  );

  displayAll$ = new BehaviorSubject(false);

  coinControl = new FormControl({
    value: null,
  }, Validators.required);
  model = this._fb.group({
    coin: this.coinControl,
  });

  data$ = combineLatest([this.dataService.getDeposits(), this.coinControl.valueChanges, this.displayAll$]).pipe(
    map(([data, coin, displayAll]) => data.filter((v) => v.currency === coin || displayAll)),
  );

  selectedBalance: WalletBalanceInterface;

  selectedBalance$ = combineLatest([this.dataService.currencyOptions$, this.coinControl.valueChanges]).pipe(
    map(([options, coin]) => options.find((op) => op.value.currency === coin)?.value),
    tap((v) => this.selectedBalance = v),
  );

  constructor(
    public dataService: DataService,
    private _route: ActivatedRoute,
    private _fb: FormBuilder,
    public dialog: MatDialog,
    public http: HttpClient,
    public router: Router,
    private _baseService,
  ) { }

  public openDeposit(key, currency, tag) {
    this.dialog.open(WalletDepositComponent,
      {data: {key, currency, tag, blockedMarkets: []}});
  }

  ngOnInit() {
    this.dataService.getBalances()
      .subscribe((data: Array<WalletBalanceInterface>)  => {
        this.isWalletWorking = data.every((bal: WalletBalanceInterface) => bal.state === 'working');
        if (!this.isWalletWorking) {
          this.router.navigate(['/user-settings/wallets']);
        }
      });
  }

  ngAfterViewInit(): void {
    if (this._route.snapshot.params.currency) {
      this.coinControl.setValue(this._route.snapshot.params.currency);
      //this.coinControl.disable();
    }

    this.coinControl.valueChanges.subscribe(value => {
      this.onChangeSelect(value);
    });
  }

  onChangeSelect(event: any) {
    const minSumForMessage = this.http.get<any>(`${this._baseService.baseEndpoint}api/v1/user/deposits/minimal-amount?currency=${event}`);
    minSumForMessage.subscribe((data) => {
      this.minDepositSum = data.res.minDepositAmt;
    }, (error) => {
      console.error('Error fetching data:', error);
    });
  }

}
