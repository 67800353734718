import { Injectable } from '@angular/core';
import {computed, observable} from 'mobx';
import {MyProfileInterface} from '../models/my-profile.model';
import {DataService} from '../services/data.service';
import {GeoState} from './geo.state';
import {USERS_AUTHORISED_FOR_FIAT_MODAL, USERS_AUTHORISED_FOR_STOP_LOSS} from '../app.constants';
import {BalanceInterface} from '../models/balance.model';
import {DepositWithdrawalInterface} from '../models/deposit-withdrawal.model';
import {takeUntil} from 'rxjs/operators';
import {MarketLastPriceInterface} from '../models/market-last-price.model';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class ProfileState {
  usersAuthorisedForFiatModal = new Set<number>(USERS_AUTHORISED_FOR_FIAT_MODAL);
  usersAuthorisedForStopLossTakeProfit = new Set<number>(USERS_AUTHORISED_FOR_STOP_LOSS);

  @observable userId: number;
  @observable userProfile: MyProfileInterface;
  @observable userIsCookiesAgreed: boolean;
  @observable isVisibleProfileEmptyFields: boolean;
  @observable isUserCompletedProfileForm: boolean;
  @observable arrayNotCompletedProfileFields: string[];
  @observable userBalances: Array<BalanceInterface>;
  @observable walletToCheck: string;
  @observable withdrawsAvailableLimit: number;
  @observable totalDepositUSDT: number;

  @computed get storagedUserId(): number {
    return this.userId || +localStorage.getItem('visible-set');
  }

  @computed get isTestAccount(): boolean {
    return [ 134, 370, 1306 ].some(x => x === +this.userId);
  }

  @computed get isAuthorisedForStopLossTakeProfit(): boolean {
    // return this.usersAuthorisedForStopLossTakeProfit.has(this.storagedUserId);
    return true;  //  remove this line if you want to define authorised user
  }

  @computed get isAuthorisedForFiat(): boolean {
    return this.usersAuthorisedForFiatModal.has(this.storagedUserId);
  }

  constructor(private _dataService: DataService, private _geoState: GeoState) { }

  setUserProfile(user: MyProfileInterface) {
    this.userProfile = user;
    this._isUserCompletedProfileFormGetter(user);
    this._geoState.setResidenceCountry(+user.residence_country_id);
    this._geoState.setCitizenshipCountry(+user.country_id);
  }

  loadUserProfile() {
    this._dataService.getUserProfile().toPromise().then(
      (user: MyProfileInterface) => {
        this.userId = user.id;
        localStorage.setItem('visible-set', '' + user.id);
        this.setUserProfile(user);
      }
    );
  }

  loadUserProfileIfEmpty() {
    if (!this.userProfile) {
      this.loadUserProfile();
    }
  }

  clearUserProfile() {
    this.userId = undefined;
    this.userProfile = undefined;
    this._geoState.setUserIsoCountry(undefined);
    this._geoState.setResidenceCountry(undefined);
    this._geoState.setResidenceCountry(undefined);
    localStorage.removeItem('visible-set');
  }

  checkUserIsCookiesAgreed() {
    if (!localStorage.getItem('isCookiesAgree') || +localStorage.getItem('isCookiesAgree') === 0) {
      this.userIsCookiesAgreed = false;
    }
  }

  private _isUserCompletedProfileFormGetter(res: MyProfileInterface) {
    // this.getUserProfile()
    //   .subscribe((res: MyProfileInterface) => {
    const myProfileFormValue = res;
    const keysProfileForm = Object.keys(myProfileFormValue);
    // console.log(keysProfileForm);
    const notCompletedFieldsArray = [];
    keysProfileForm.forEach(key => {
      if (key !== 'phone'
        && key !== '2fa_enabled'
        && key !== 'email_verification_enabled'
        && key !== 'is_GDPR'
        && key !== 'withdrawals_mail_enable'
        && key !== 'cookies_agreed'
        && key !== 'address'
        && key !== 'apt'
        && key !== 'city'
        && key !== 'residence_country_id'
        && key !== 'state'
        && key !== 'zip'
        && key !== 'kz_id_number'
        && key !== 'passport_number'
      ) {
        if (key === 'birthday') {
          if (myProfileFormValue[key] && myProfileFormValue[key].length < 10) {
            notCompletedFieldsArray.push(key);
          }
        } else {
          if (!myProfileFormValue[key] || !('' + myProfileFormValue[key]).length) {
            notCompletedFieldsArray.push(key);
          }
        }
      }
    });
    this.isUserCompletedProfileForm = notCompletedFieldsArray && notCompletedFieldsArray.length === 0;

    if (notCompletedFieldsArray.length > 0) {
      this.arrayNotCompletedProfileFields = notCompletedFieldsArray;
    }
    if (res.cookies_agreed === 0 || res.cookies_agreed === 1) {
      this.userIsCookiesAgreed = !!res.cookies_agreed;
    }
    // });
  }

  getLastUserBalance(): Array<BalanceInterface> {
    //console.log('emittedBalance:' + this.userBalances);
    return this.userBalances;
  }

  setUserBalance(data: Array<BalanceInterface>) {
    this.userBalances = data;
  }

  setWalletToCheck(data: string) {
    this.walletToCheck = data;
  }

  public calculateWithdrawsAvailableLimit(totalBalance: number, totalLimit: number) {
    this.withdrawsAvailableLimit = totalLimit - totalBalance;
  }

  public setTotalDepositUSDT(data: number) {
    this.totalDepositUSDT = data;
  }

  getTransactions(): void {
    this._dataService.getDepositsWithdrawals().toPromise()
      .then(data => {
        const deposits = [];
        const withdrawals = [];
        let history: Array<any>;
        if (data.deposits && (<Array<DepositWithdrawalInterface>>data.deposits).length) {
          const ds = data.deposits;
          for (let i = 0; i < ds.length; i++) {
            deposits.push(Object.assign({}, ds[i], {'type': 'Deposit'}));
          }
        }
        if (data.withdrawals && (<Array<DepositWithdrawalInterface>>data.withdrawals).length) {
          const ws = data.withdrawals;
          for (let i = 0; i < ws.length; i++) {
            withdrawals.push(Object.assign({}, ws[i], {'type': 'Withdrawal'}));
          }
        }
        history = withdrawals
          .concat(deposits)
          .sort((a, b) => +new Date(b.created_at) - +new Date(a.created_at));
        // const withdrawsHistory = this.getTransactionsHistoryByType(instant-buy, 'Withdrawal');
        this.countTotalDeposits(history);
      });
  }

  private countTotalDeposits(_history: Array<DepositWithdrawalInterface>): void {
    let totalDepositUSDT = 0;
    this._dataService.getTicksHistory().toPromise()
      .then((data: Array<any>) => {
        totalDepositUSDT = 0;
        const currencyPricesBTC = data.filter(item => item.name === 'BTC')[0];
        const BTCtoUSDTlastPrice = ( currencyPricesBTC
          && (<MarketLastPriceInterface>currencyPricesBTC.USDT)
          && (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last )
          ? (<MarketLastPriceInterface>currencyPricesBTC.USDT).Last : 0;
        const deposits = this.getTransactionsHistoryByType(_history, 'Deposit');
        deposits.forEach((transaction: DepositWithdrawalInterface) => {
          if (transaction.currency !== 'USDT') {
            const currencyPrices = data.filter(item => {
              return item.name === transaction.currency;
            })[0];
            const currencyPricesUSDT = (
              (<MarketLastPriceInterface>currencyPrices
                && (<MarketLastPriceInterface>currencyPrices.USDT)
                && (<MarketLastPriceInterface>currencyPrices.USDT).Last)
                ? +(<MarketLastPriceInterface>currencyPrices.USDT).Last
                : ( (<MarketLastPriceInterface>currencyPrices
                && (<MarketLastPriceInterface>currencyPrices.BTC)
                && (<MarketLastPriceInterface>currencyPrices.BTC).Last)
                ? (<MarketLastPriceInterface>currencyPrices.BTC).Last * BTCtoUSDTlastPrice : 0 )
            );
            totalDepositUSDT += currencyPricesUSDT * (+transaction.quantity);
          } else {
            totalDepositUSDT += +transaction.quantity;
          }
        });
        this.setTotalDepositUSDT(totalDepositUSDT);
      }, error => {
        return observableThrowError(error);
      });
  }

  private getTransactionsHistoryByType(history: Array<DepositWithdrawalInterface>, type?: any): Array<DepositWithdrawalInterface> {
    if (type) {
      return history.filter(item => {
        return item.type.toLowerCase() === type.toLowerCase();
      });
    } else {
      return history;
    }
  }


}
