import {Component, OnInit} from '@angular/core';
import {DataService} from '../../services/data.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {CryptoCredentialPaymentStatusComponent} from '../../dialogs/crypto-credential-payment-status/crypto-credential-payment-status';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CryptoCredentialProgressStatusComponent} from '../../dialogs/crypto-credential-progress-status/crypto-credential-progress-status';
import {RouterEvent} from '@angular/router';

@Component({
  selector: 'app-crypto-credential-sending',
  templateUrl: './crypto-credential-sending.component.html',
  styleUrls: ['./crypto-credential-sending.component.scss']
})
export class CryptoCredentialSendingComponent implements OnInit {
  public selectedTokenProps;
  public isStartingStep = true;
  public isSendMoneyStep = false;
  public selectedCoin = 'Выберите монету';
  public selectedOption: string | null = '';
  public isCoinsDropdownOpen = false;
  public selectedImageSrc = '';
  public dropdownFilterInputValue = '';
  public coins = [];
  public coinQuantity: number;
  public userName: string;
  public userNote: string;

  constructor(
    private dialog: MatDialog,
    private dataService: DataService,
    private router: Router)  {
  }

  coinsMock = [
    {
      imgSrc: 'assets/landing/icons/bitcoin_icon.svg',
      shortName: 'BTC',
      fullName: 'Bitcoin'
    },
    {
      imgSrc: 'assets/landing/icons/bitcoin_cash_icon.svg',
      shortName: 'BCH',
      fullName: 'Bitcoin cash'
    },
    {
      imgSrc: 'assets/landing/icons/cardano_icon.svg',
      shortName: 'ADA',
      fullName: 'Cardano'
    },
    {
      imgSrc: 'assets/landing/icons/ethereum_icon.svg',
      shortName: 'ETH',
      fullName: 'Ethereum'
    },
    {
      imgSrc: 'assets/landing/icons/ripple_icon.svg',
      shortName: 'XRP',
      fullName: 'Ripple'
    },
    {
      imgSrc: 'assets/landing/icons/tether_icon.svg',
      shortName: 'USDT',
      fullName: 'Tether'
    },
  ];

  ngOnInit() {
    if (!localStorage.getItem('jwtToken')) {
      this.router.navigate(['/']);
    }
    this.dataService.withdrawCryptoCredentialMasterCard().subscribe(res => console.log('res', res));
    this.dataService.getWallets().subscribe(wallets => {
      console.log('WALLETS', wallets);
    });
this.getFilterredCoins();
    console.log('on init');
  }

  getFilterredCoins() {
    this.coins = this.coinsMock
      .filter(i => i.fullName.toLowerCase().includes(this.dropdownFilterInputValue)
        || i.shortName.toLowerCase().includes(this.dropdownFilterInputValue));
  }

  getCurrentDetailInfo() {
    return '';
  }

  getCurrencyName() {
    return '';
  }
  toggleCoinsDropdown() {
    this.isCoinsDropdownOpen = !this.isCoinsDropdownOpen;
  }

  selectCoin(coin: string, coinShortName: string) {
    console.log('COIN', coin);
    this.selectedCoin = coin;
    this.isCoinsDropdownOpen = false; // Закрываем дропдаун после выбора

    this.selectedImageSrc = this.generateImgSrc(coinShortName);
  }

  generateImgSrc(coinShortName: string): string {
    switch (coinShortName) {
      case 'BTC':
        return 'assets/landing/icons/bitcoin_icon.svg';
      case 'ADA':
        return 'assets/landing/icons/cardano_icon.svg';
      case 'BCH':
        return 'assets/landing/icons/bitcoin_cash_icon.svg';
      case 'XRP':
        return 'assets/landing/icons/ripple_icon.svg';
      case 'ETH':
        return 'assets/landing/icons/ethereum_icon.svg';
      case 'USDT':
        return 'assets/landing/icons/tether_icon.svg';
    }
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
  }

  continuePayment() {
    if (this.isStartingStep) {
      this.isStartingStep = false;
      this.isSendMoneyStep = true;
    } else {
      this.dataService.withdrawCryptoCredentialMasterCard().subscribe(res => console.log('res', res));
      const dialogRef = this.dialog.open(CryptoCredentialProgressStatusComponent);
      setTimeout(() => {
        dialogRef.close(CryptoCredentialProgressStatusComponent);
      }, 3000);
    }

    // this.dialog.open(CryptoCredentialProgressStatusComponent);


  }
}

