import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewSelectComponent } from './components/new-select/new-select.component';
import { TranslateModule } from '@ngx-translate/core';
import { NewInputComponent } from './components/new-input/new-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegExpDirective } from './directives/reg-exp.directive';
import { FearGreedIndexComponent } from '../pages/search/fear-greed-index/fear-greed-index.component';
import { NewSmartInputComponent } from './components/new-smart-input/new-smart-input.component';
import { EmailChangeComponent } from '../dialogs/email-change/email-change.component';
import { OtpPageComponent } from '../dialogs/otp-page/otp-page.component';
import { OtpResponseComponent } from '../dialogs/otp-response/otp-response.component';
import { TableComponent } from './components/table/table.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NewDatepickerComponent } from './components/new-datepicker/new-datepicker.component';
import { ConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { NumberOnlyDirective } from './directives/only-number.directive';
import { MatDatepickerComponent } from './components/mat-datepicker/mat-datepicker.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { DatepickerHeaderComponent } from './components/mat-datepicker/datepicker-header/datepicker-header.component';

@NgModule({
  declarations: [
    NewSelectComponent,
    NewInputComponent,
    FearGreedIndexComponent,
    NewSmartInputComponent,
    EmailChangeComponent,
    OtpPageComponent,
    OtpResponseComponent,
    TableComponent,
    PaginationComponent,
    NewDatepickerComponent,
    ConfirmEmailComponent,
    MatDatepickerComponent,
    DatepickerHeaderComponent,

    // directives
    RegExpDirective,
    OnlyLettersDirective,
    NumberOnlyDirective,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
  ],
  exports: [
    NewSelectComponent,
    NewInputComponent,
    FearGreedIndexComponent,
    NewSmartInputComponent,
    TableComponent,
    PaginationComponent,
    NewDatepickerComponent,
    ConfirmEmailComponent,
    MatDatepickerComponent,

    // directives
    RegExpDirective,
    OnlyLettersDirective,
    NumberOnlyDirective,
  ],
})
export class SharedModule {}
