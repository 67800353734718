import { Injectable } from '@angular/core';
import { observable, action } from 'mobx';

@Injectable({
  providedIn: 'root',
})
export class LanguageState {
  @observable currentLanguage = window.localStorage.getItem('preferred_lang');

  @action setLanguage(language: string) {
    this.currentLanguage = language;
  }

  @action getCurrentLanguage(): string {
    return this.currentLanguage;
  }
}
