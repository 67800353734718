import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { DataService } from '../../services/data.service';
import {
  ActivatedRoute,
  Router,
  RoutesRecognized,
  NavigationEnd,
} from '@angular/router';
import { filter, map, tap } from 'rxjs/operators';
import {DomainService} from '../../services/domain.service';

@Component({
  selector: 'app-main-wrapper',
  templateUrl: './main-wrapper.component.html',
  styleUrls: ['./main-wrapper.component.scss'],
})
export class MainWrapperComponent implements OnInit {
  isLightTheme = false;

  isMobile = window.innerWidth < 860;

  isMarketPage = true;

  isMenuOpened = false;

  isLandingPage = false;
  public isAdminProject: boolean = false;

  constructor(
    public dataService: DataService,
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _domainService: DomainService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.isMobile = window.innerWidth < 860;
  }

  ngOnInit(): void {
    // if (localStorage.getItem('preferred_lang') === 'zh') {
    //   window.localStorage.setItem('preferred_lang', 'ru');
    //   window.location.reload();
    // }

    this.checkIfLandingPage(this.router.url);
    this.checkIfAdmin(this.router.url);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.checkIfLandingPage(event.urlAfterRedirects);
        this.checkIfAdmin(event.url);
      });

    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter().subscribe((data) => {
      this.isLightTheme = data;
    });
  }

  checkIfLandingPage(url: string): void {
    this.isLandingPage = url.includes('landing') || url === '/';
  }

  private checkIfAdmin(url: string): void {
    this.isAdminProject = url.includes('admin-intebix');
  }

  navigate(url: string) {
    this.router.navigate([url]);
    this.isMenuOpened = false;
    this.isLandingPage = false;
  }
}
