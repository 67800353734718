import {Component, OnDestroy, OnInit} from '@angular/core';
import {LandingService} from '../../services/landing.service';
import {MatTableDataSource} from '@angular/material/table';
import {Sort} from '@angular/material/sort';
import {MarketPair} from '../../interfaces/market-pair';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';

@Component({
  selector: 'app-bix-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss'],
})
export class MarketComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = [
    'MarketNumber',
    'MarketName',
    'Last',
    'BaseVolumeUSDT',
    // 'percentChange',
    'Link',
  ];
  dataSource = new MatTableDataSource<MarketPair>([]);

  sortData: Sort = { active: 'BaseVolumeUSDT', direction: 'asc' };

  private _destroySubject = new Subject<void>();

  // isMobile = window.innerWidth <= 810;

  constructor(private _landingService: LandingService) {}

  ngOnInit(): void {
    this._landingService.marketsDetails$
      .pipe(takeUntil(this._destroySubject))
      .subscribe((data) => {
        // const newData = data.map((market: MarketPair) => {
        //   let iconSrc;
        //   if (market.MarketName.includes('BTC')) {
        //     iconSrc = 'assets/landing/icons/bitcoin_icon.svg';
        //   } else if (market.MarketName.includes('BCH')) {
        //     iconSrc = 'assets/landing/icons/bitcoin_cash_icon.svg';
        //   } else if (market.MarketName.includes('XRP')) {
        //     iconSrc = 'assets/landing/icons/ripple_icon.svg';
        //   } else if (market.MarketName.includes('ADA')) {
        //     iconSrc = 'assets/landing/icons/cardano_icon.svg';
        //   } else if (market.MarketName.includes('ETH')) {
        //     iconSrc = 'assets/landing/icons/ethereum_icon.svg';
        //   }
        //   return {...market, iconSrc: iconSrc};
        // })
        this.dataSource.data = data
          .filter((market) => {
            if (market.MarketName.includes('BTC') && market.MarketName.includes('USDT')
              || market.MarketName.includes('ETH') && market.MarketName.includes('USDT')
              || market.MarketName.includes('BNB') && market.MarketName.includes('USDT')
              || market.MarketName.includes('XRP') && market.MarketName.includes('USDT')
              || market.MarketName.includes('SOL') && market.MarketName.includes('USDT')) {
              return market;
            }
          })
          .map((market: MarketPair) => {
            let iconSource = '';
            if (market.MarketName.includes('BTC')) {
              iconSource = 'assets/landing/icons/bitcoin_icon.svg';
            } else if (market.MarketName.includes('BNB')) {
              iconSource = 'assets/landing/icons/bnb_icon.svg';
            } else if (market.MarketName.includes('XRP')) {
              iconSource = 'assets/landing/icons/ripple_icon.svg';
            } else if (market.MarketName.includes('SOL')) {
              iconSource = 'assets/landing/icons/solana_icon.svg';
            } else if (market.MarketName.includes('ETH')) {
              iconSource = 'assets/landing/icons/ethereum_icon.svg';
            }
            return {...market, iconSrc: iconSource, };
          });
        this.sort(this.sortData);
      });
  }

  ngOnDestroy(): void {
    this._destroySubject.next();
  }

  formattedMarketName(marketName: string): string {
    // return marketName?.split('-')?.reverse()?.join('/');
    return marketName?.split('-')?.reverse()?.join(' ');
  }

  marketNamePart(marketName: string, index: number): string {
    return marketName?.split('-')?.reverse()[index];
  }

  generateImgSrc(name: string): string {
    return `https://trade.intebix.kz/assets/img/coins/${name
      ?.split('-')[1]
      ?.toLowerCase()}.png`;
  }

  goToTrade(pair: MarketPair): void {
    location.href = `https://trade.intebix.kz/trades/${pair.MarketName}`;
  }

  sort(event: Sort): void {
    this.sortData = event;

    const sortFn = (a: MarketPair, b: MarketPair) => {
      const firstPair = event.direction === 'asc' ? a : b;
      const secondPair = event.direction === 'asc' ? b : a;

      switch (event.active) {
        case 'MarketName':
          const str1 = this.formattedMarketName(firstPair.MarketName);
          const str2 = this.formattedMarketName(secondPair.MarketName);
          return str1.localeCompare(str2);

        case 'Last':
          return (
            +(secondPair.LastPriceUSDT || secondPair.Last) -
            +(firstPair.LastPriceUSDT || firstPair.Last)
          );

        case 'percentChange':
          return +secondPair.percentChange - +firstPair.percentChange;

        case 'BaseVolumeUSDT':
          return +secondPair.BaseVolumeUSDT - +firstPair.BaseVolumeUSDT;

        default:
          return 0;
      }
    };

    this.dataSource.data = this.dataSource.data.sort(sortFn);
  }
  formatNumber(value: number, toFixedValue: number) {
    const [integerPart, decimalPart] = value.toFixed(toFixedValue).split('.');

    const integerPartToLocaleString = parseInt(integerPart, 10).toLocaleString('en-US');

    return `${integerPartToLocaleString}.${decimalPart}`;
  }

  formatMarketName(marketName: string) {
    switch (marketName) {
      case 'BTC':
        return 'Bitcoin';
      case 'BCH':
        return 'Bitcoin Cash';
      case 'ADA':
        return 'Cardano';
      case 'XRP':
        return 'Ripple';
      case 'ETH':
        return 'Ethereum';
      case 'USDT':
        return 'Tether';
      case 'AAVE':
        return 'Aave';
      case 'ARB':
        return 'Arbitrum';
      case 'WAVES':
        return 'Waves';
      case 'ATOM':
        return 'Cosmos';
      case 'BAL':
        return 'Balancer';
      case 'FTM':
        return 'Fantom';
      case 'VET':
        return 'VeChain';
      case 'HBAR':
        return 'Hedera Hashgraph';
      case 'IMX':
        return 'Immutable X';
      case 'MKR':
        return 'Maker';
      case 'OP':
        return 'Optimism';
      case 'TRX':
        return 'Tron';
      case 'THETA':
        return 'Theta network';
      case 'GRT':
        return 'The Graph';
      case 'INJ':
        return 'Injective Protocol';
      case 'RUNE':
        return 'Thor Chain';
      case 'USDP':
        return 'Pax Dollars';
      case 'AVAX':
        return 'Avalanche';
      case 'NEAR':
        return 'Near Protocol';
      case 'UNI':
        return 'Unidswap';
      case 'BNB':
        return 'Bnb';
      case 'SOL':
        return 'Solana';
      case 'TON':
        return 'Toncoin';
    }
  }

  generateImgSrcForCoins(name: any): string {
    // let coin;
    // if (coin?.MarketName.includes('USDT')) {
    //
    // }
  // const coinName = name?.MarketFullName.split(' ')[0];
  const coinName = name?.CurrencyBase;
  // console.log('COIN NAME', coinName);
    switch (coinName) {
      case 'BTC':
        return 'bitcoin_icon';
      case 'BCH':
        return 'bitcoin_cash_icon';
      case 'ADA':
        return 'cardano_icon';
      case 'XRP':
        return 'ripple_icon';
      case 'ETH':
        return 'ethereum_icon';
      case 'USDT':
        return 'tether_icon';
      case 'AAVE':
        return 'aave_icon';
      case 'ARB':
        return 'arb_icon';
      case 'WAVES':
        return 'waves_icon';
      case 'ATOM':
        return 'cosmos_icon';
      case 'BAL':
        return 'balancer_icon';
      case 'FTM':
        return 'fantom_icon';
      case 'VET':
        return 've_chain_icon';
      case 'HBAR':
        return 'hedera_hashgraph_icon';
      case 'IMX':
        return 'immutable_x_icon';
      case 'MKR':
        return 'maker_icon';
      case 'OP':
        return 'optimism_icon';
      case 'TRX':
        return 'tron_icon';
      case 'THETA':
        return 'theta_network_icon';
      case 'GRT':
        return 'the_graph_icon';
      case 'INJ':
        return 'injective_protocol_icon';
      case 'RUNE':
        return 'thor_chain_icon';
      case 'USDP':
        return 'pax_dollar_icon';
      case 'AVAX':
        return 'avalance_icon';
      case 'NEAR':
        return 'near_protocol_icon';
      case 'UNI':
        return 'uniswap_icon';
      case 'BNB':
        return 'bnb_icon';
      case 'SOL':
        return 'solana_icon';
      case 'TON':
        return 'ton_icon';
    }
    // return `assets/img/coins/${name?.split('-')[1]?.toLowerCase()}.png`;
  }

}
