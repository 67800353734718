import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {
  OrderbooksHistoryMarketInterface,
  OrderbooksHistoryMarketModel,
  OrderbooksMarketInterface,
  OrderbooksMarketModel
} from '../../../models/orderbooks-history-market.model';
import {SocketDataClass, SocketFieldClass, SocketFieldFillClass} from '../../../models/socket-data.class';
import {Subject} from 'rxjs/internal/Subject';
import {map, takeUntil, tap} from 'rxjs/operators';
import {ThemesModel} from '../../../models/themes.model';
import {roundByPlaces} from '../../../pipes/wallets/number-place.pipe';

@Component({
  selector: 'app-order-book',
  templateUrl: './order-book.component.html',
  styleUrls: ['./order-book.component.scss'],
})
export class OrderBookComponent implements OnInit, OnDestroy {
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public isLightTheme = false;
  public orderBookData: OrderbooksHistoryMarketInterface;
  public orderBookDataThird: OrderbooksHistoryMarketInterface = {
    ask: [],
    bid: []
  };
  public orderBookMaxTotals = {
    ask: 0,
    bid: 0,
  };
  public baseCurrency: string;
  public currentCurrency: string;
  public Math: any;
  public isVisibleActualPair = false;
  private destroySubject$: Subject<void> = new Subject();
  public ThemesModel = ThemesModel;

  public lastPrice$ = this.dataService.currentMarketDetails$.pipe(
    tap((pair) => {
      this.lastUp = pair.lastUp;
      this.lastDown = pair.lastDown;
      this.lastPriceCache = +pair?.Last;
    }),
    map((pair) => pair?.Last),
  );

  public lastPriceCache = 0;
  public lastUp = false;
  public lastDown = false;

  constructor(
    public dataService: DataService,
    public svgService: SvgService
  ) {
    this.Math = Math;
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    this.dataService.getMarketsList().subscribe(res => console.log('GET MARKET LIST', res));
    this.initialDataLoading();
    // this.dataService.EmitSubscribeToOrderbookUpdates();
    // this.dataService.EmitSubscribeToTradeUpdates();
    // this.dataService.getMarketPairNewEmitter()
    //   .pipe(takeUntil(this.destroySubject$))
    //   .subscribe(() => {
    //     this.isVisibleActualPair = true;
    //     this.initialDataLoading();
    //     setTimeout(() => this.isVisibleActualPair = false, 32000);
    //   });
    // const [baseCurrency, currentCurrency] = this.dataService.market.split('-');
    // this.baseCurrency = baseCurrency;
    // this.currentCurrency = currentCurrency;
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
    this.dataService.EmitUnsubscribeToOrderbookUpdates();
    this.dataService.EmitUnsubscribeToTradeUpdates();

  }

  setOrderBookData(data) {
    this.orderBookData = data;
    Object.assign(this.orderBookDataThird, {
      bid: JSON.parse(JSON.stringify(data.bid))
        .sort((a, b) => {
          if (a.Price < b.Price) {
            return 1;
          }
          if (a.Price > b.Price) {
            return -1;
          }
          return 0;
        }).slice(0, 10)
    });

    Object.assign(this.orderBookDataThird, {
      ask: JSON.parse(JSON.stringify(data.ask))
        .sort((a, b) => {
          if (+a.Price > +b.Price) {
            return 1;
          }
          if (+a.Price < +b.Price) {
            return -1;
          }
          return 0;
        }).slice(0, 10).reverse()
    });
  }

  private calculateMaxTotals() {
    return {
      bid: this.orderBookDataThird.bid.reduce((acc, v) => acc < v.Total ? v.Total : acc, 0),
      ask: this.orderBookDataThird.ask.reduce((acc, v) => acc < v.Total ? v.Total : acc, 0),
    };
  }

  public initialDataLoading() {
    this.dataService.getOrderBookHistory()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((history: OrderbooksHistoryMarketInterface) => {
        history.ask = history.ask.map((v) => ({...v, Total: v.Size * v.Price, Sum: v.Size}));
        history.bid = history.bid.map((v) => ({...v, Total: v.Size * v.Price, Sum: v.Size}));
        this.setOrderBookData(history);
        this.orderBookMaxTotals = this.calculateMaxTotals();

        this.dataService.updateOrderBookData(this.orderBookData);
        this.dataService.SubscribeToOrderbookUpdates()
          .pipe(takeUntil(this.destroySubject$))
          .subscribe((socketData: SocketDataClass) => {
            this.orderBookData = this.mergeRounded(this.orderBookData, 9);
            this.orderBookData = this.addToOrderBookNewDates(
              this.mergeRounded(SocketDataClass.getMarketModels(socketData), 9),
              this.orderBookData
            );
            // this.orderBookData = this.updateOrderBookDates(this.mergeRoundedSocket(socketData, 9), this.orderBookData);
            this.orderBookData = this.deleteEmptyOrderBookDates(this.orderBookData);
            this.orderBookData = this.sortFormatedOrderBookDates(this.orderBookData);
            this.orderBookData = this.formatOrderBookDates(this.orderBookData);
            this.setOrderBookData(this.orderBookData);

            this.orderBookMaxTotals = this.calculateMaxTotals();

            this.dataService.updateOrderBookData(this.orderBookData);
          });

      });
  }

  public getTotalWidthThirdTheme(total, key) {
    return total * 100 / this.orderBookMaxTotals[key] + '%';
  }

  public mergeRounded(data: OrderbooksHistoryMarketInterface, places: number): OrderbooksHistoryMarketInterface {
    const getMap = (src: OrderbooksMarketModel[]) => src.reduce(
      (acc, v) => {
        const index = roundByPlaces(v.Price, places);
        const finded = acc.get(index);
        return acc.set(index, finded
          ? {...finded, Price: index, Size: finded.Size + v.Size, Total: finded.Total + v.Total, Sum: finded.Sum + v.Sum}
          : {...v, Price: index});
      },
      new Map<number, OrderbooksMarketModel>([])
    );
    return {
      bid: Array.from(getMap(data.bid).values()),
      ask: Array.from(getMap(data.ask).values()),
    };
  }

  public mergeRoundedSocket(data: SocketDataClass, places: number): SocketDataClass {
    function getMap(src: Array<SocketFieldClass>) {
      return src.filter((v) => v.Type !== 0).reduce(
        (acc, v) => {
          const index = roundByPlaces(v.Rate, places);
          const finded = acc.get(index);
          return acc.set(index, finded
            ? {...finded, Rate: index, Quantity: finded.Quantity + v.Quantity}
            : {...v, Rate: index});
        },
        new Map<number, SocketFieldClass>([])
      );
    }

    function getFilledMap(src: Array<SocketFieldFillClass>) {
      return src.reduce(
        (acc, v) => {
          const index = roundByPlaces(v.Rate, places);
          const finded = acc.get(index);
          return acc.set(index, finded
            ? {...finded, Rate: index, Quantity: finded.Quantity + v.Quantity}
            : {...v, Rate: index});
        },
        new Map<number, SocketFieldFillClass>([])
      );
    }

    return {
      ...data,
      Buys: Array.from(getMap(data.Buys).values()),
      Sells: Array.from(getMap(data.Sells).values()),
      Fills: Array.from(getFilledMap(data.Fills).values()),
    };
  }

  public addToOrderBookNewDates(socketData: OrderbooksHistoryMarketInterface,
                                marketDateHistory: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const buys = socketData.bid;
    const sells = socketData.ask;
    const bidsMap = new Map<number, number>(marketDateHistory.bid.map((v) => [+v.Price, +v.Size]));
    let asksMap = new Map<number, number>(marketDateHistory.ask.map((v) => [+v.Price, +v.Size]));
    let bids = [...marketDateHistory.bid];
    let asks = [...marketDateHistory.ask];

    if (buys && buys.length) {
      for (let i = 0; i < buys.length; i++) {
        if (buys[i].Size && buys[i].Size > 0 && buys[i].Price && buys[i].Price > 0) {
          const size = (bidsMap.get(+buys[i].Price) || 0) + +buys[i].Size;
          bidsMap.set(+buys[i].Price, size);
          asks = asks.filter((v) => v.Price > buys[i].Price);
        }
      }
      bids = Array.from(bidsMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
      asksMap = new Map(asks.map((v) => [+v.Price, +v.Size]));
    }
    if (sells && sells.length) {
      for (let i = 0; i < sells.length; i++) {
        if (sells[i].Size && sells[i].Size > 0 && sells[i].Price && sells[i].Price > 0) {
          const size = (asksMap.get(+sells[i].Price) || 0) + +sells[i].Size;
          asksMap.set(+sells[i].Price, size);
          bids = bids.filter((v) => v.Price < +sells[i].Price);
        }
      }
      asks = Array.from(asksMap.entries()).map(([Price, Size]) => ({Price, Size, Total: Size * Price, Sum: Size}));
    }
    return {...marketDateHistory, ask: asks, bid: bids};
  }

  public updateOrderBookDates(socketData: SocketDataClass,
                              marketDateHistory: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const treadingTypeCurrent = Object.keys(socketData).splice(1, 2);
    const treadingTypeHistory = Object.keys(marketDateHistory);

    for (let i = 0; i < treadingTypeCurrent.length; i++) {
      socketData[treadingTypeCurrent[i]].forEach((currentOrderBook) => {

        const index = this.findOrderBook(marketDateHistory[treadingTypeHistory[i]], 'Price', 'Rate', currentOrderBook);

        if (index !== -1) {
          const historyOrderBook = marketDateHistory[treadingTypeHistory[i]][index];
          const updatedOrderBook = this.updateOrderBook(currentOrderBook, historyOrderBook);

          if (updatedOrderBook === null) {
            marketDateHistory[treadingTypeHistory[i]].splice(index, 1);
          } else {
            marketDateHistory[treadingTypeHistory[i]][index] = updatedOrderBook;
          }
        }
        return marketDateHistory;

      });
    }
    return marketDateHistory;
  }

  public updateOrderBook(currentOrderBook, historyOrderBook) {
    switch (currentOrderBook.Type) {
      //ADD QUANTITY
      case 0 : {
        historyOrderBook.Size += currentOrderBook.Quantity;
        return historyOrderBook;
      }
      //REMOVE CURRENT
      case 1: {
        return null;
      }
      //UPDATE QUANTITY
      case 2: {
        return {
          Size: currentOrderBook.Quantity,
          Price: currentOrderBook.Rate
        };
      }
    }
  }

  public sortFormatedOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {
        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return -1;
            }
            if (a.Price < b.Price) {
              return 1;
            }
          });
        }

        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].sort((a, b) => {
            if (a.Price > b.Price) {
              return 1;
            }
            if (a.Price < b.Price) {
              return -1;
            }
          });
        }
      }
    }
    return sortedMarketData;
  }

  public deleteEmptyOrderBookDates(marketData): OrderbooksHistoryMarketInterface {
    const sortedMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {

        if (treadingType === 'bid') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }

        if (treadingType === 'ask') {
          sortedMarketData[treadingType] = marketData[treadingType].filter((data: OrderbooksMarketInterface) => {
            return data.Price && data.Size && data.Total;
          });
        }
      }
    }
    return sortedMarketData;
  }

  public formatOrderBookDates(marketData: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const newMarketData: OrderbooksHistoryMarketInterface = new OrderbooksHistoryMarketModel();

    for (const treadingType in marketData) {
      if (marketData.hasOwnProperty(treadingType)) {
        newMarketData[treadingType] = marketData[treadingType].reduce((dateAccumulator, date, i) => {
          if (i === 0) {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: date.Size
            });
          } else {
            dateAccumulator.push({
              Size: date.Size,
              Price: date.Price,
              Total: date.Size * date.Price,
              Sum: dateAccumulator[dateAccumulator.length - 1].Sum + date.Size
            });
          }
          return dateAccumulator;
        }, []);
      }
    }
    return newMarketData;
  }

  public mergeEqualOrderBookDates(marketData: OrderbooksHistoryMarketInterface): OrderbooksHistoryMarketInterface {
    const asksMap: Map<number, OrderbooksMarketModel> = marketData.ask.reduce(
      (acc, order) => {
        const mergedOrder: OrderbooksMarketModel = acc.get(+order.Price);
        return acc.set(order.Price, {...order, Size: order.Size + +mergedOrder?.Size, Total: order.Total + +mergedOrder?.Total});
      },
      new Map<number, OrderbooksMarketModel>()
    );
    const bidsMap: Map<number, OrderbooksMarketModel> = marketData.bid.reduce(
      (acc, order) => {
        const mergedOrder: OrderbooksMarketModel = acc.get(+order.Price);
        return acc.set(order.Price, {...order, Size: order.Size + +mergedOrder?.Size, Total: order.Total + +mergedOrder?.Total});
      },
      new Map<number, OrderbooksMarketModel>()
    );
    console.log('merge equal', marketData, {ask: Array.from(asksMap.values()), bid: Array.from(bidsMap.values())});
    return {ask: Array.from(asksMap.values()), bid: Array.from(bidsMap.values())};
  }

  public findOrderBook(array, fieldOld, fieldNew, value) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][fieldOld] === value[fieldNew]) {
        return i;
      }
    }
    return -1;
  }

  public copyAmountAndPriceToExchange(amount: string, price: string) {
    this.dataService.passExchangePrice(this.dataService.convertExpToNumber(+price));
    this.dataService.setExchangePrice(this.dataService.convertExpToNumber(+price));
    // this.dataService.setExchangeAmount(this.dataService.convertExpToNumber(+amount));
    // this.dataService.passExchangeAmount(this.dataService.convertExpToNumber(+amount));
  }
}
