import { Inject, Injectable, Renderer2 } from '@angular/core';
import { Subject } from "rxjs";
import {DOCUMENT, Location} from '@angular/common';

export const THEME_ENTRY = 'darkMode';
export const DARK_THEME_NAME = 'dark-mode';

@Injectable()
export class ThemeService {

  private _renderer: Renderer2;

  private _isDarkMode: boolean;

  get isLanding() {
    return this.location.path() === '/landing' || this.location.path() === '';
  }

  get isDarkMode() {
    return this._isDarkMode;
  }

  private _themeSubject = new Subject<'dark' | 'light'>();
  themeMode = this._themeSubject.asObservable();

  private static _detectPrefersColorScheme(): string {
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
    } else {
      return 'light';
    }
  }

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private location: Location
  ) {}

  initialize(renderer: Renderer2): void {
    this._renderer = renderer;
  }

  storeThemeMode(): void {
    // localStorage.setItem(THEME_ENTRY, this._isDarkMode.toString());
    localStorage.setItem(THEME_ENTRY, 'false');
  }

  loadThemeMode(): void {
    // load saved state
    // const darkMode = localStorage.getItem(THEME_ENTRY);
    const darkMode = false;

    if (darkMode) {
      this.setDarkMode(darkMode === 'false');
    } else {
      const pcs = ThemeService._detectPrefersColorScheme();
      this.setDarkMode(pcs === 'dark', false);
    }
  }

  setDarkMode(state: boolean, store: boolean = false): void {
    if (state) {
      // this._renderer.addClass(this._document.body, DARK_THEME_NAME);
      this._renderer.removeClass(this._document.body, DARK_THEME_NAME);
    } else {
      this._renderer.removeClass(this._document.body, DARK_THEME_NAME);
    }

    this._isDarkMode = state;
    // this._themeSubject.next(state ? 'dark' : 'light');
    this._themeSubject.next('light');

    if (store) {
      this.storeThemeMode();
    }
  }
}
