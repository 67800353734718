import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DataService} from '../../services/data.service';
import {GeoState} from '../../store/geo.state';
import {FormBuilder} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-crypto-credential-progress-status',
  templateUrl: './crypto-credential-progress-status.html',
  styleUrls: ['./crypto-credential-progress-status.scss']
})
export class CryptoCredentialProgressStatusComponent implements OnInit {
  isInvalid: boolean;
  userEmail: string;
  isRegisterByEmailButtonClicked = false;
  isEmailAvailable: boolean;
  isDisabledRegisterByEmailButton = true;
  isWasCheckedUserAvailability: boolean;
  isDataLoading: boolean;
  isUserNameAvailable: boolean;
  inputValue: string;
  isCheckedCryptoCredentialData = false;
  isSuccessfullPayment = true;

  isStartingStep = true;
  isRegistrationStep = false;
  isRegistrationProcessing = false;
  isRegistrationSuccess = false;
  isRegistrationFailed = false;
  customRedirect: boolean;

  constructor(public dialogRef: MatDialogRef<CryptoCredentialProgressStatusComponent>,
              public dataService: DataService,
              public geoState: GeoState,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder,
              private authService: AuthService,
              private router: Router) {
    this.dialogRef.afterClosed()
      .subscribe(() => {
        this.isStartingStep = true;
        this.isRegistrationStep = false;
        this.isRegistrationProcessing = false;
        this.isRegistrationSuccess = false;
        this.isRegistrationFailed = false;
        this.router.navigate(['', {outlets: {popup: null}}]).then();
      });
    }

  registerUserByEmail() {
    this.isRegisterByEmailButtonClicked = true;
    if (this.isDisabledRegisterByEmailButton) {
      return;
    }

    this.isRegisterByEmailButtonClicked = true;
    this.isStartingStep = false;
    this.isRegistrationStep = false;
    this.isRegistrationProcessing = true;
    if (this.userEmail) {
      this.dataService.registerMastercardAccountByEmail(this.userEmail)
        .pipe(
          catchError(error => {
            console.error('Ошибка:', error); // Логируем ошибку
            alert('Произошла ошибка при выполнении запроса');
            this.isRegistrationProcessing = false;
            this.isRegistrationFailed = true;
            return of([]);
          })
        )
        .subscribe((data) => {
          if (data?.status === 'ACTIVE') {
            this.isRegistrationProcessing = false;
            this.isRegistrationSuccess = true;
          } else {
            this.isRegistrationProcessing = false;
            this.isRegistrationFailed = true;
          }
        });
    }
  }

  registerUserByNickname() {
    this.isStartingStep = false;
    this.isRegistrationStep = false;
    this.isRegistrationProcessing = true;
    if (this.inputValue) {
      this.dataService.registerMastercardAccountByNickname(this.inputValue)
        .pipe(
          catchError(error => {
            console.error('Ошибка:', error); // Логируем ошибку
            alert('Произошла ошибка при выполнении запроса');
            this.isRegistrationProcessing = false;
            this.isRegistrationFailed = true;
            return of([]);
          })
        )
        .subscribe((data) => {
          if (data?.status === 'ACTIVE') {
            this.isRegistrationProcessing = false;
            this.isRegistrationSuccess = true;
          } else {
            this.isRegistrationProcessing = false;
            this.isRegistrationFailed = true;
          }
        });
    }
  }

  validateInput(value: string) {
    const regex = /^[a-zA-Z0-9]*$/;

    if (regex.test(value)) {
      this.inputValue = value;
      this.isInvalid = false;
      this.checkUserAvailability();
    } else {
      this.isInvalid = true;
    }
  }

  checkUserAvailability() {
    if (this.inputValue === '' || this.inputValue === undefined) {
      this.isWasCheckedUserAvailability = false;
      return;
    }
    this.isDataLoading = true;
    setTimeout(() => {
      this.dataService.checkMastercardAccountStatus(this.inputValue)
        .pipe(
          catchError(error => {
            if (error.status === 404) {
              this.isUserNameAvailable = true;
              this.isDataLoading = false;
              this.isWasCheckedUserAvailability = true;
              console.error = () => {};
            }
            return of([]);
          })
        )
        .subscribe(data => {
          if (data[0]?.status === 'ACTIVE') {
            this.isUserNameAvailable = false;
            this.isDataLoading = false;
            this.isWasCheckedUserAvailability = true;
          }
        });
    }, 1000);
  }

  ngOnInit() {
    this.dataService.getUserProfile()
      .subscribe(v => {
      this.userEmail = v.email;
      console.log('EMAIL NAME', v?.email.replace(/@[\w.-]+\.[a-zA-Z]{2,}/g, ''));
      this.dataService.checkMastercardAccountStatus(v?.email.replace(/@[\w.-]+\.[a-zA-Z]{2,}/g, ''))
        .pipe(
          catchError(error => {
            if (error.status === 404) {
              this.isEmailAvailable = true;
              this.isDisabledRegisterByEmailButton = false;
            }
            console.error('Ошибка:', error);
            return of([]);
          })
        )
        .subscribe(data => {

        });
    });
  }

  closeModal() {
    this.dialogRef.close();
  }
}

