import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
  OnDestroy,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { IShortEntity } from '../../interfaces/shortEntity.interface';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-select',
  templateUrl: './new-select.component.html',
  styleUrls: ['./new-select.component.scss'],
})
export class NewSelectComponent implements OnInit, OnDestroy, OnChanges {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() optionsProps: IShortEntity[] = [];
  @Input() disabledProps: boolean = false;
  @Input() boldLabelProps: boolean = false;
  @Input() withSearchProps: boolean = false;
  @Input() formControlProps: FormControl;
  @Input() isInstantBuyTab: boolean;

  @Output() optionSelected = new EventEmitter<IShortEntity>();

  private destroySubject$: Subject<void> = new Subject();
  public searchControl = new FormControl('');
  public isActive: boolean = false;
  public selectedOption: IShortEntity = null;
  public cloneOptions: IShortEntity[];
  private globalClickUnlistener: () => void;
  private subscriptions: Subscription = new Subscription();

  constructor(private _renderer: Renderer2, private _elementRef: ElementRef) {}

  ngOnInit(): void {
    this.initializeValues();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.optionsProps && changes.optionsProps.currentValue) {
      this.cloneOptions = [...this.optionsProps];
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.detachGlobalClickListener();
  }

  private initializeValues(): void {
    this.searchControl.valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        takeUntil(this.destroySubject$)
      )
      .subscribe((searchText) => {
        this.performSearch(searchText || '');
      });
    if (this.isInstantBuyTab) {
      this.selectOption({
        id: 1,
        value: 'KZT',
        label: 'KZT',
        icon: 'assets/fiat-transactions/kzt.svg',
        labelPart2: 'Казахский тенге',
      });
    }

    this.selectedOption = this.formControlProps?.value || null;

    if (this.formControlProps) {
      const controlSub = this.formControlProps.valueChanges.subscribe(
        (value) => {
          this.selectedOption = value;
        }
      );
      this.subscriptions.add(controlSub);
    }
  }

  toggleDropdown(): void {
    if (!this.disabledProps) {
      this.isActive = !this.isActive;

      if (this.isActive) {
        this.attachGlobalClickListener();
      } else {
        this.detachGlobalClickListener();
      }
    }
  }

  selectOption(option: IShortEntity): void {
    this.selectedOption = option;
    this.formControlProps?.setValue(option);
    this.optionSelected.emit(option);
    this.isActive = false;
    this.detachGlobalClickListener();
  }

  private attachGlobalClickListener(): void {
    this.globalClickUnlistener = this._renderer.listen(
      'document',
      'click',
      (event: MouseEvent) => this.onGlobalClick(event)
    );
  }

  private detachGlobalClickListener(): void {
    if (this.globalClickUnlistener) {
      this.globalClickUnlistener();
      this.globalClickUnlistener = null;
    }
  }

  private onGlobalClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;

    if (!this._elementRef.nativeElement.contains(target)) {
      this.isActive = false;
      this.detachGlobalClickListener();
    }
  }

  private performSearch(searchText: string): void {
    if (!searchText) {
      this.cloneOptions = [...this.optionsProps];
    } else {
      this.cloneOptions = this.optionsProps.filter((option) =>
        option.label.toLowerCase().includes(searchText.toLowerCase())
      );
    }
  }
}
