import {AfterViewInit, Component, OnInit, Renderer2, AfterContentInit} from '@angular/core';
import { DataService } from './services/data.service';
import { AlertComponent } from './dialogs/alert/alert.component';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../environments/environment';
import { TitleService } from './services/title.service';
import {GeoState} from './store/geo.state';
import {ThemeService} from './services/theme.service';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AuthService} from './services/auth.service';
import {HttpClient} from '@angular/common/http';
import {DomainService} from './services/domain.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public reCaptcha_version = environment.name || 'prod';
  constructor(
    public dataService: DataService,
    private geoState: GeoState,
    private titleService: TitleService,
    private dialog: MatDialog,
    private _themeService: ThemeService,
    private _renderer: Renderer2,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    private  http: HttpClient,
    private _domainService: DomainService,
  ) {

      if (
        !this._authService.isLoggedIn &&
        window.location.port !== '4202' &&
        !window.location.pathname.includes('receive-token')
      ) {
        console.log(window.location);
        localStorage.setItem('lastPath', window.location.pathname);
        // TODO: Временно отключили SSO
        // this._router.navigate(['/sso'], {queryParams: {check: true}}).then();
      }
      this._themeService.initialize(this._renderer);
      this._themeService.loadThemeMode();
      this.titleService.init();
      const keys = Object.keys(localStorage);
      const tradingviews = [].concat(this.getKeysByTemplate('tradingview', keys), this.getKeysByTemplate('tvxwevents', keys));
      let isCreated = false;
      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === 'tradingview.defIsUpdated') {
          isCreated = true;
          const defIsUpdated = localStorage.getItem('tradingview.defIsUpdated');
          switch (defIsUpdated) {
            case '1':
              // console.log('********* update is loaded');
              break;
            default: this.removeLSkeys(tradingviews);
          }
        }
      }
      if (!isCreated) {
        localStorage.setItem('tradingview.defIsUpdated', '1');
        this.removeLSkeys(tradingviews);
      }
      setTimeout(() => this.getMessageTypeForAlertModal(), 100);
      // this.dataService.passMessageTypeForAlertModal(1);
      // this.dataService.setMessageTypeForAlertModal(1);
      // if (
      //   this.reCaptcha_version === 'prod'
      // ) {
        setTimeout(() => {
          this.geoState.findUserCountry();
        }, 500);
      // }
  }

  ngOnInit() {
    // this._domainService.handleDomainChange();

    // this._router.events.subscribe((event) => {
    //   if (event instanceof NavigationEnd) {
    //     const bodyTag = document.getElementsByTagName('body')[0];
    //
    //     if (event.url.includes('landing') || event.url === '/') {
    //       bodyTag.style.backgroundColor = '#FCFCFD';
    //     } else {
    //       bodyTag.style.backgroundColor = '#22426A';
    //     }
    //   }
    // });
  }

  public getMessageTypeForAlertModal() {
    let messageType = this.dataService.getMessageTypeForAlertModal();
    if (messageType) {
      this.openAlertModal(messageType);
    }
    this.dataService.getMessageTypeForAlertModalEmitter()
      .subscribe(data => {
        messageType = data;
        if (messageType) {
          this.openAlertModal(messageType);
        }
      });
  }

  public openAlertModal(messageType: number, messageText?: string) {
    if (messageType) {
      this.dialog.open(AlertComponent, {
        data: { 'messageType': messageType, 'messageText': messageText || 'no message' }
      });
    }
  }

  public getKeysByTemplate(template: string, keys: Array<string>): Array<string> {
    const result = [];
    for (let i = 0; i < keys.length; i++) {
      if (!(keys[i].toLowerCase().indexOf(template.toLowerCase()) === -1)) {
        result.push(keys[i]);
      }
    }
    return result;
  }

  public removeLSkeys(keysToRemove: Array<string>) {
    for (let i = 0; i < keysToRemove.length; i++) {
      localStorage.removeItem(keysToRemove[i]);
    }
  }
}
