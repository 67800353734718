import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DataService} from '../../../services/data.service';
import {SvgService} from '../../../services/svg.service';
import {BalanceInterface} from '../../../models/balance.model';
import {AuthService} from '../../../services/auth.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ThemesModel} from '../../../models/themes.model';
import {SubscribeToUpdateTradesDataInterface} from '../../../models/subscribe-to-update-trades-data.model';

@Component({
  selector: 'app-balances',
  templateUrl: './balances.component.html',
  styleUrls: ['./balances.component.scss'],
})
export class BalancesComponent implements OnInit, OnDestroy {
  public isLightTheme = false;
  @Input() public isMinimized: boolean;
  @Output() public minimized = new EventEmitter<any>();
  public balances = [];
  destroySubject$: Subject<void> = new Subject();
  public sortParams = {
    sortByToken: 'down',
    sortByBalance: ''
  };
  ThemesModel = ThemesModel;

  constructor(public dataService: DataService,
              public authService: AuthService,
              public svgService: SvgService) {
    if (window.localStorage.getItem('isLightTheme') === 'true') {
      this.isLightTheme = true;
    } else {
      this.isLightTheme = this.dataService.getIsLightTheme();
    }
    this.dataService.getIsLightThemeEmitter()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(data => {
        this.isLightTheme = data;
      });
  }

  ngOnInit() {
    if (this.authService.isLoggedIn) {
      this.getServerBalances();
      this.dataService.SubscribeToUpdateBalances()
        .pipe(takeUntil(this.destroySubject$))
        .subscribe((data: any) => {
          if (data.u) {
            this.getServerBalances();
          }
        });
      this.subsToUpdateOrders();
    }

    this.dataService.refreshedStopLimitOrders
      .pipe(takeUntil(this.destroySubject$))
      .subscribe(() => {
        this.getServerBalances();
      });
  }

  ngOnDestroy() {
    this.destroySubject$.next(null);
    this.destroySubject$.complete();
  }

  public subsToUpdateOrders() {
    this.dataService.SubscribeToUpdateOrders()
      .pipe(takeUntil(this.destroySubject$))
      .subscribe((data: SubscribeToUpdateTradesDataInterface) => {
        if (data.u) {
          this.getServerBalances();
        }
      });
  }

  public getServerBalances() {
    this.dataService.getBalances()
      .subscribe((data: Array<BalanceInterface>) => {
        this.balances = data.filter(balance => balance.balance > 0);
      });
  }

  public toggleSort(param) {
    if (this.sortParams[param] === 'up') {
      this.sortParams[param] = 'down';
    } else if (this.sortParams[param] === 'down') {
      this.sortParams[param] = '';
    } else {
      this.sortParams[param] = 'up';
    }
  }
}
