import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DomainService implements OnDestroy {

  private handleDomainChangeBound = this.handleDomainChange.bind(this); // Привязка метода к текущему контексту

  constructor() {
    // window.addEventListener('popstate', this.handleDomainChangeBound);
    // window.addEventListener('popstate', this.handleDomainChangeBound);
  }

  public handleDomainChange() {
    const currentDomain = window.location.hostname;
    console.log('Current domain:', currentDomain);

    const token = this.getTokenFromCookies();
    if (token) {
      localStorage.setItem('jwtToken', token);
    }
  }

  public getTokenFromCookies(): string | null {
    const match = document.cookie.match(new RegExp('(^| )jwtToken=([^;]+)'));
    console.log('DOMAIN SERVICE MATCH', match);
    console.log('DOMAIN SERVICE MATCH[2]', match ? match[2] : null);
    return match ? match[2] : null;
  }

  // Отписка от событий
  ngOnDestroy(): void {
    // window.removeEventListener('popstate', this.handleDomainChangeBound);
    // window.removeEventListener('popstate', this.handleDomainChangeBound);
  }
}
