export class SvgService {

  public star = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 512 512"><path fill="currentColor" d="M256 372.686L380.83 448l-33.021-142.066L458 210.409l-145.267-12.475L256 64l-56.743 133.934L54 210.409l110.192 95.525L131.161 448z"/></svg>`;
  public bigStar = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 512 512"><path fill="currentColor" d="M256 372.686L380.83 448l-33.021-142.066L458 210.409l-145.267-12.475L256 64l-56.743 133.934L54 210.409l110.192 95.525L131.161 448z"/></svg>`;

  public calculator = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 448 512"> <path fill="currentColor" d="M0 464V48C0 21.49 21.49 0 48 0h352c26.51 0 48 21.49 48 48v416c0 26.51-21.49 48-48 48H48c-26.51 0-48-21.49-48-48zm384-284V76c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v104c0 6.627 5.373 12 12 12h296c6.627 0 12-5.373 12-12zM128 308v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm256 128V268c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v168c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm-256 0v-40c0-6.627-5.373-12-12-12H76c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm128-128v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12zm0 128v-40c0-6.627-5.373-12-12-12h-40c-6.627 0-12 5.373-12 12v40c0 6.627 5.373 12 12 12h40c6.627 0 12-5.373 12-12z"></path></svg>`;

  public linkedin = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#d8d8d8" d="M19,3A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3H19M18.5,18.5V13.2A3.26,3.26 0 0,0 15.24,9.94C14.39,9.94 13.4,10.46 12.92,11.24V10.13H10.13V18.5H12.92V13.57C12.92,12.8 13.54,12.17 14.31,12.17A1.4,1.4 0 0,1 15.71,13.57V18.5H18.5M6.88,8.56A1.68,1.68 0 0,0 8.56,6.88C8.56,5.95 7.81,5.19 6.88,5.19A1.69,1.69 0 0,0 5.19,6.88C5.19,7.81 5.95,8.56 6.88,8.56M8.27,18.5V10.13H5.5V18.5H8.27Z" />
</svg>`;

  public linkedinadd = `<svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="#d8d8d8" d="M21,21H17V14.25C17,13.19 15.81,12.31 14.75,12.31C13.69,12.31 13,13.19 13,14.25V21H9V9H13V11C13.66,9.93 15.36,9.24 16.5,9.24C19,9.24 21,11.28 21,13.75V21M7,21H3V9H7V21M5,3A2,2 0 0,1 7,5A2,2 0 0,1 5,7A2,2 0 0,1 3,5A2,2 0 0,1 5,3Z" />
</svg>`;

  public angleDown = `<svg width="7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>`;

  public minimizeAngle = `<svg width="12" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>`;

  public renewal = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 11 10"><g fill="none" fill-rule="evenodd" stroke="currentColor"><path d="M9.03 5.075c0 2.03-1.469 3.676-3.28 3.676-.9 0-1.712-.405-2.305-1.06M2.468 5.075c0-2.03 1.47-3.675 3.282-3.675.82 0 1.572.338 2.147.896"/><path d="M7.519 6.067l1.577-1.511 1.334 1.746M1.32 3.14l.921 2.083L4.08 4.202"/></g></svg>`;

  public checked = `<svg width="10" height="10" viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" ><path fill="currentColor" stroke="currentColor" stroke-width="5" d="M98.131,211.199c-1.521,0-3-0.541-4.165-1.541l-59.731-51.2c-2.685-2.3-2.994-6.34-0.693-9.024  c2.3-2.682,6.347-2.991,9.024-0.694L97.2,195.571L212.547,47.271c2.172-2.79,6.197-3.29,8.981-1.125  c2.79,2.172,3.294,6.194,1.125,8.981L103.185,208.728c-1.075,1.381-2.669,2.262-4.409,2.44  C98.56,211.189,98.344,211.199,98.131,211.199z"/></svg>`;

  public xClose = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 7 8"><path fill="#fff" fill-rule="evenodd" d="M6.472 1.227L3.869 3.83l2.603 2.603-.657.656-2.603-2.603L.61 7.09l-.656-.656L2.556 3.83-.047 1.227.61.571l2.603 2.603L5.815.57z"/></svg>`;

  public xCloseGreen = `<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 7 8"><path fill="#000000" fill-rule="evenodd" d="M6.472 1.227L3.869 3.83l2.603 2.603-.657.656-2.603-2.603L.61 7.09l-.656-.656L2.556 3.83-.047 1.227.61.571l2.603 2.603L5.815.57z"/></svg>`;

  public arrow = `
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g fill="none" fill-rule="evenodd">
                  <rect width="20" height="20" fill="#162935" rx="2"/>
                  <path fill="#FFF" fill-rule="nonzero"
                  d="M7.978 9.965l5.63-4.856a.625.625 0 1 0-.79-.968L6 9.965l6.818 5.844a.625.625 0 1 0 .79-.969l-5.63-4.875z"/>
              </g>
          </svg>`;

  public fullArrow = `
          <svg xmlns="http://www.w3.org/2000/svg" width="63" height="30" viewBox="0 0 63 30">
              <g fill="none" fill-rule="evenodd">
                  <rect width="63" height="30" fill="#162935" rx="2"/>
                  <path fill="#FFF"
                  fill-rule="nonzero"
                  d="M33.86 14.965l-5.63-4.856a.625.625 0 1 1 .79-.968l6.818 5.824-6.818 5.844a.625.625 0 1 1-.79-.969l5.63-4.875z"/>
              </g>
          </svg>`;

  public theme1 = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#FFF" fill-rule="evenodd" d="M0 12.059V9.798h10.824v2.26h-5.06V12h-.94v.059H0zM0 13h4.824v3H2a2 2 0 0 1-2-2v-1zm5.765 0h5.059v3h-5.06v-3zM16 12.059h-4.235V9.798H16v2.26zM16 13v1a2 2 0 0 1-2 2h-2.235v-3H16zM0 8.857V6.529h10.824v2.328H0zm16 0h-4.235V6.529H16v2.328zM0 5.588V2a2 2 0 0 1 2-2h8.824v5.588H0zm16 0h-4.235V0H14a2 2 0 0 1 2 2v3.588z"/>
  </svg>`;

  public theme2 = `
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
        <path fill="#FFF" fill-rule="evenodd" d="M4.118 10.046V0h9.044v10.046H4.118zm0 1.176h9.044V20H4.118v-8.778zM20 10.046h-5.662V0H18a2 2 0 0 1 2 2v8.046zm0 1.176V18a2 2 0 0 1-2 2h-3.662v-8.778H20zM2.941 0v20H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h.941z"/>
    </svg>`;

  public theme3 = `
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 20 20">
    <path fill="#FFF" fill-rule="evenodd" d="M4.118 15.662v-2.574h11.544v2.574H4.118zm0 1.176h11.544V20H4.118v-3.162zm0-4.926V9.338h11.544v2.574H4.118zm0-3.75V0h11.544v8.162H4.118zM16.838 0H18a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-1.162V0zM2.941 0v20H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h.941z"/>
  </svg>`;

  public xCloseLightTheme = `<svg width="14" version="1.1" xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 0 64 64" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 64 64">
  <g>
    <path fill="#1D1D1B" d="M28.941,31.786L0.613,60.114c-0.787,0.787-0.787,2.062,0,2.849c0.393,0.394,0.909,0.59,1.424,0.59   c0.516,0,1.031-0.196,1.424-0.59l28.541-28.541l28.541,28.541c0.394,0.394,0.909,0.59,1.424,0.59c0.515,0,1.031-0.196,1.424-0.59   c0.787-0.787,0.787-2.062,0-2.849L35.064,31.786L63.41,3.438c0.787-0.787,0.787-2.062,0-2.849c-0.787-0.786-2.062-0.786-2.848,0   L32.003,29.15L3.441,0.59c-0.787-0.786-2.061-0.786-2.848,0c-0.787,0.787-0.787,2.062,0,2.849L28.941,31.786z"/>
  </g>
</svg>`;
}
