import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MatDateFormats,
} from '@angular/material/core';
import { MatCalendar } from '@angular/material/datepicker';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'datepicker-header',
  templateUrl: 'datepicker-header.component.html',
  styleUrls: ['datepicker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatepickerHeaderComponent<D> implements OnDestroy {
  private destroySubject = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    private _cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => _cdr.markForCheck());
  }

  ngOnDestroy(): void {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  get periodLabel(): string {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  openYearView(): void {
    this._calendar.currentView = 'multi-year';
    this._cdr.markForCheck();
  }

  previousClicked(mode: 'month' | 'year'): void {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        -24
      );
    } else {
      this._calendar.activeDate =
        mode === 'month'
          ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
    }
  }

  nextClicked(mode: 'month' | 'year'): void {
    if (this._calendar.currentView === 'multi-year') {
      this._calendar.activeDate = this._dateAdapter.addCalendarYears(
        this._calendar.activeDate,
        24
      );
    } else {
      this._calendar.activeDate =
        mode === 'month'
          ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
          : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
    }
  }
}
