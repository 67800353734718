import {Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
@Component({
  selector: 'app-crypto-credential-payment-status',
  templateUrl: './crypto-credential-payment-status.html',
  styleUrls: ['./crypto-credential-payment-status.scss']
})
export class CryptoCredentialPaymentStatusComponent {
  public isCheckedCryptoCredentialData =  false;
  public isSuccessfullPayment =  true;

  constructor(private dialog: MatDialogRef<CryptoCredentialPaymentStatusComponent>) {
  }

  closeModal() {
    this.dialog.close();
  }
}

