import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';

@Component({
  selector: 'app-mat-datepicker',
  templateUrl: './mat-datepicker.component.html',
  styleUrls: ['./mat-datepicker.component.scss'],
})
export class MatDatepickerComponent {
  @Input() labelProps: string;
  @Input() placeholderProps: string;
  @Input() formControlProps: FormControl;
  @Input() disabledProps: boolean = false;

  datepickerHeaderComponent: typeof DatepickerHeaderComponent =
    DatepickerHeaderComponent;
}
